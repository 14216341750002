import React, { useState, useEffect } from "react";
import "./DecisionTree.css";

const DecisionTreeTable = ({ data }) => {
  const [tableData, setTableData] = useState(data);

  useEffect(() => {
    setTableData(data);
  }, [data]);

  if (!tableData || tableData.length === 0) {
    return <div>No data available</div>;
  }

  const headers = Object.keys(tableData[0]);

  return (
    <table>
      <thead>
        <tr>
          {headers.map((header) => (
            <th
              key={header}
              className={header === "Decision" ? "highlight" : ""}
            >
              {header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {tableData.map((row, index) => (
          <tr key={index}>
            {headers.map((header) => (
              <td
                key={header}
                className={header === "Decision" ? "highlight" : ""}
              >
                {row[header]}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default DecisionTreeTable;
