import * as Blockly from "blockly/core";
import {
  DATA_SELECTION_FIELD,
  ROOT_NODE_FIELD,
  LEFT_NODE_FIELD,
  RIGHT_NODE_FIELD,
  LEAF_NODE_FIELD,
  OPERATOR_FIELD,
  getselectedDataset,
  setselectedDataset,
} from "../../constants";

import dataSets from "./DTData.json";

//let selectedData = Object.keys(dataSets)[0];
let decisionColumn = null;

export function setDataset(d) {
  setselectedDataset(d);
}

export function initDecisionColumn() {
  decisionColumn = Object.keys(dataSets[getselectedDataset()][0]).at(-1);
}

function removeDuplicates(arr) {
  return [...new Set(arr)];
}

function getAllValues(objects, key) {
  return objects.map((a) => {
    return a[key];
  });
}

export function getUniqueValues(objects, key) {
  return removeDuplicates(getAllValues(objects, key));
}

export function getDatasetMenu() {
  var dataInputNode = this.inputList[0];
  var dataOptions = new Blockly.FieldDropdown(function () {
    const dOptions = [];
    for (let k of Object.keys(dataSets)) {
      dOptions.push([String(k), String(k)]);
    }
    return dOptions;
  });
  dataInputNode.appendField(dataOptions, DATA_SELECTION_FIELD);
  this.getField(DATA_SELECTION_FIELD).setValue(getselectedDataset());

  this.setOnChange(function (event) {
    if (
      event.type === Blockly.Events.BLOCK_CHANGE &&
      event.name === DATA_SELECTION_FIELD
    ) {
      setDataset(this.getFieldValue(DATA_SELECTION_FIELD));
      initDecisionColumn();
    }
  });
}

export function getNodeAttributes() {
  var inputRootNode = this.inputList[1];
  var rOptions = new Blockly.FieldDropdown(function () {
    const rootOptions = [];
    for (let k of Object.keys(dataSets[getselectedDataset()][0]).slice(0, -1)) {
      rootOptions.push([String(k), String(k)]);
    }
    return rootOptions;
  });
  inputRootNode.appendField(rOptions, ROOT_NODE_FIELD);
  var rootOptions = this.getField(ROOT_NODE_FIELD);

  function getAllNodeValues() {
    return getUniqueValues(
      dataSets[getselectedDataset()],
      rootOptions.getValue()
    );
  }

  function addOptionsToNode(block, nodeName, inputIndex) {
    var inputNode = block.inputList[inputIndex];

    var comparisonOptions = new Blockly.FieldDropdown(function () {
      var operators = ["=", "!="].concat(
        typeof getAllNodeValues()[0] === "number" ? ["<", ">", "<=", ">="] : []
      );
      var nodeOptionValues = [];
      for (let v of operators) {
        nodeOptionValues.push([String(v), String(v)]);
      }
      return nodeOptionValues;
    });
    inputNode.appendField(comparisonOptions, nodeName + OPERATOR_FIELD);

    var nodeOptions = new Blockly.FieldDropdown(function () {
      var nodeOptionValues = [];
      for (let v of getAllNodeValues()) {
        nodeOptionValues.push([String(v), String(v)]);
      }
      return nodeOptionValues;
    });
    inputNode.appendField(nodeOptions, nodeName);
  }

  addOptionsToNode(this, LEFT_NODE_FIELD, 3);
  addOptionsToNode(this, RIGHT_NODE_FIELD, 5);

  function resetNodes(block, root = false) {
    function resetFieldOptions(fieldName) {
      var field = block.getField(fieldName);
      var options = field.getOptions(false);
      field.setValue(options[0][1]);

      if (!root) {
        var f = block.getField(fieldName + OPERATOR_FIELD);
        var opt = f.getOptions(false);
        f.setValue(opt[0][1]);
      }
    }

    resetFieldOptions(LEFT_NODE_FIELD);
    resetFieldOptions(RIGHT_NODE_FIELD);
    if (root) {
      resetFieldOptions(ROOT_NODE_FIELD);
    }
  }

  this.setOnChange(function (event) {
    if (event.type === Blockly.Events.BLOCK_CHANGE) {
      if (event.name === ROOT_NODE_FIELD && this.id === event.blockId) {
        resetNodes(this);
      } else if (event.name === DATA_SELECTION_FIELD) {
        resetNodes(this, true);
      }
    }
  });
}

export function getTargetColumn() {
  var dataInputNode = this.inputList[0];
  initDecisionColumn();
  var dataOptions = new Blockly.FieldDropdown(function () {
    const dOptions = [];
    for (let k of getUniqueValues(
      dataSets[getselectedDataset()],
      decisionColumn
    )) {
      dOptions.push([String(k), String(k)]);
    }
    return dOptions;
  });
  dataInputNode
    //.appendField(decisionColumn + "?", DECISION_COLUMN_FIELD)
    .appendField(dataOptions, LEAF_NODE_FIELD);

  this.setOnChange(function (event) {
    if (event.type === Blockly.Events.BLOCK_CHANGE) {
      if (event.name === DATA_SELECTION_FIELD) {
        //this.getField(DECISION_COLUMN_FIELD).setValue(decisionColumn + "?");
        var field = this.getField(LEAF_NODE_FIELD);
        var options = field.getOptions(false);
        field.setValue(options[0][1]);
      }
    }
  });
}
