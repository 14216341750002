import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function TaskResultComponent(props) {
  const { t } = useTranslation();
  const showTaskResult = props.showTaskResult;
  const isSuccessful = props.isSuccessful;
  const headline = props.headline;
  const description = props.description;
  const handleNextLevel = props.handleNextLevel;
  const hasNextLevel = props.hasNextLevel;
  const errorList = props.errorList;
  //const big = props.big;

  const size = true ? "lg" : "m";

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (showTaskResult) {
      handleShow();
    }
  }, [showTaskResult]);

  const { setTaskResult } = props;

  useEffect(() => {
    if (!show) {
      if (setTaskResult != null) {
        setTaskResult(false);
      }
    }
  }, [show, setTaskResult]);

  return (
    <Modal size={size} show={show}>
      <Modal.Header closeButton onClick={handleClose}>
        <Modal.Title>{headline}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={"modal-turtle-speech-bubble"}>
          <span
            className={`speech-bubble ${
              isSuccessful && "speech-bubble-successful"
            }`}
          >
            {description} {errorList}
          </span>
          <img
            height={728 / 4}
            width={898 / 4}
            src={"/tutorial-turtle-transparent.png"}
            alt={""}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          {t("close")}
        </Button>
        {isSuccessful && hasNextLevel ? (
          <Button
            onClick={() => {
              handleClose();
              handleNextLevel();
            }}
          >
            {" "}
            {t("next_level")}
          </Button>
        ) : (
          ""
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default TaskResultComponent;
