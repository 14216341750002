/**
 * @license
 *
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import * as Blockly from "blockly/core";
import i18n from "../../i18n";
import {
  BLOCK_COLOR,
  CLASS_BLOCK_COLOR,
  IMAGE_BLOCK_SIZE,
  IMAGE_CLASSIFICATION_PATH,
} from "../../constants";

Blockly.Blocks["train"] = {
  init: function () {
    this.jsonInit({
      type: "train",
      message0: i18n.t("image_classification.blocks.train"),
      previousStatement: null,
      nextStatement: null,
      colour: BLOCK_COLOR,
      tooltip: "",
      helpUrl: "",
    });
  },
};

Blockly.Blocks["wait_seconds"] = {
  init: function () {
    this.jsonInit({
      type: "wait_seconds",
      message0: i18n.t("image_classification.blocks.wait_seconds") + " %1",
      args0: [
        {
          type: "field_number",
          name: "seconds",
          value: 0,
          min: 0,
        },
      ],
      previousStatement: null,
      nextStatement: null,
      colour: BLOCK_COLOR,
      tooltip: "",
      helpUrl: "",
    });
  },
};

Blockly.Blocks["create_screenshot"] = {
  init: function () {
    this.jsonInit({
      type: "create_screenshot",
      message0: i18n.t("image_classification.blocks.create_screenshot") + " %1",
      args0: [
        {
          type: "field_dropdown",
          name: "class",
          options: [
            [i18n.t("image_classification.blocks.class1"), "class1"],
            [i18n.t("image_classification.blocks.class2"), "class2"],
          ],
        },
      ],
      previousStatement: null,
      nextStatement: null,
      colour: BLOCK_COLOR,
      tooltip: "",
      helpUrl: "",
    });
  },
};

Blockly.Blocks["webcam_predict"] = {
  init: function () {
    this.jsonInit({
      type: "webcam_predict",
      message0: i18n.t("image_classification.blocks.webcam_predict"),
      previousStatement: null,
      nextStatement: null,
      colour: BLOCK_COLOR,
      tooltip: "",
      helpUrl: "",
    });
  },
};

const clickOnCutomImage = (placeholder_image) => {
  document.getElementById("file-selector").click();
  window.placeholder_image = placeholder_image;
};

Blockly.Blocks["image_custom"] = {
  init: function () {
    this.appendDummyInput("IDENTIFIER")
      .appendField(new Blockly.FieldTextInput(""), "IMAGE_ID")
      .setVisible(false);
    this.appendDummyInput("INPUT_IMAGE").appendField(
      new Blockly.FieldImage(
        IMAGE_CLASSIFICATION_PATH + "/upload.png",
        IMAGE_BLOCK_SIZE,
        IMAGE_BLOCK_SIZE,
        "*",
        clickOnCutomImage
      ),
      "PLACEHOLDER_IMAGE"
    );
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setTooltip("");
    this.setHelpUrl("");
    this.setColour(BLOCK_COLOR);
  },
};

for (let i = 1; i < 7; i++) {
  Blockly.Blocks["image_dog" + i] = {
    init: function () {
      this.appendDummyInput("IDENTIFIER")
        .appendField(new Blockly.FieldTextInput("dog" + i), "IMAGE_ID")
        .setVisible(false);
      this.appendDummyInput().appendField(
        new Blockly.FieldImage(
          IMAGE_CLASSIFICATION_PATH + "/dog" + i + ".jpg",
          IMAGE_BLOCK_SIZE,
          IMAGE_BLOCK_SIZE,
          "*"
        )
      );
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setTooltip("");
      this.setHelpUrl("");
      this.setColour(BLOCK_COLOR);
    },
  };
  Blockly.Blocks["image_cat" + i] = {
    init: function () {
      this.appendDummyInput("IDENTIFIER")
        .appendField(new Blockly.FieldTextInput("cat" + i), "IMAGE_ID")
        .setVisible(false);
      this.appendDummyInput().appendField(
        new Blockly.FieldImage(
          IMAGE_CLASSIFICATION_PATH + "/cat" + i + ".jpg",
          IMAGE_BLOCK_SIZE,
          IMAGE_BLOCK_SIZE,
          "*"
        )
      );
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setTooltip("");
      this.setHelpUrl("");
      this.setColour(BLOCK_COLOR);
    },
  };
}

Blockly.Blocks["dogs"] = {
  init: function () {
    this.jsonInit({
      type: "dogs",
      message0: i18n.t("image_classification.blocks.dogs") + ":",
      nextStatement: null,
      colour: CLASS_BLOCK_COLOR,
      tooltip: "",
      helpUrl: "",
      movable: false,
    });
  },
};

Blockly.Blocks["cats"] = {
  init: function () {
    this.jsonInit({
      type: "cats",
      message0: i18n.t("image_classification.blocks.cats") + ":",
      nextStatement: null,
      colour: CLASS_BLOCK_COLOR,
      tooltip: "",
      helpUrl: "",
      movable: false,
    });
  },
};

Blockly.Blocks["class_custom"] = {
  init: function () {
    this.jsonInit({
      type: "class_custom",
      message0: i18n.t("image_classification.blocks.class_custom") + " %1",
      args0: [
        {
          type: "field_input",
          name: "NAME",
          text: "",
        },
      ],
      nextStatement: null,
      colour: CLASS_BLOCK_COLOR,
      tooltip: "",
      helpUrl: "",
    });
  },
};

Blockly.Blocks["cnn_settings"] = {
  init: function () {
    this.jsonInit({
      type: "cnn_settings",
      message0: i18n.t("image_classification.blocks.cnn_settings"),
      args0: [
        {
          type: "input_dummy",
        },
        {
          type: "field_number",
          name: "epochs",
          value: 10,
        },
        {
          type: "input_dummy",
        },
        {
          type: "field_number",
          name: "batchSize",
          value: 4,
        },
        {
          type: "input_dummy",
        },
        {
          type: "field_number",
          name: "learningRate",
          value: 0.1,
        },
      ],
      colour: BLOCK_COLOR,
      helpUrl: "",
    });
    this.setStyle("list_blocks");
    this.setCommentText(
      i18n.t("image_classification.blocks.cnn_settings_comment")
    );
    this.comment.setBubbleSize(300, 250);
    this.setMovable(false);
  },
};
