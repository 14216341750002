import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import * as PropTypes from "prop-types";
import { Card, ProgressBar, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";

PredictImageComponent.propTypes = {
  changePredictImage: PropTypes.func,
  showUploadPredictImage: PropTypes.bool,
  showEnterPredictImageURL: PropTypes.bool,
  handleUploadPredictImage: PropTypes.func,
  handleEnterPredictImageURL: PropTypes.func,
  predictImage: PropTypes.any,
};

export function PredictImageComponent(props) {
  const { t } = useTranslation();

  function loadPredictionData() {
    const classNames = window.CLASS_NAMES ? [...window.CLASS_NAMES] : [];
    const predictions = window.PREDICTION_ARRAY
      ? [...window.PREDICTION_ARRAY]
      : [];

    const classPredictionList = classNames.map((className, index) => ({
      className: className,
      prediction:
        predictions[index] !== undefined
          ? Math.floor(predictions[index] * 100)
          : undefined,
    }));
    return classPredictionList;
  }

  const [classList, setClassList] = useState(loadPredictionData || []);

  useEffect(() => {
    const handleClassListChange = () => {
      setClassList(loadPredictionData());
    };

    window.addEventListener("refreshPredictions", handleClassListChange);

    return () => {
      window.removeEventListener("refreshPredictions", handleClassListChange);
    };
  }, []);

  const generateOptions = (prefix, count, labelKey) => {
    const options = [];
    for (let i = 1; i <= count; i++) {
      options.push(
        <option key={`${prefix}${i}`} value={`${prefix}${i}`}>
          {t(labelKey, { id: i })}
        </option>
      );
    }
    return options;
  };

  return (
    <React.Fragment>
      <Card style={{ marginBottom: "16px" }}>
        <Card.Header>
          {t("image_classification.title_predict_image")}
        </Card.Header>
        <Card.Body>
          <Form.Select
            style={{
              width: "250px",
              marginBottom: "10px",
            }}
            aria-label="Default select example"
            size={"sm"}
            onChange={props.changePredictImage}
          >
            <option value="Select one Image for prediction">
              {t("image_classification.predict_image_select.select_image")}
            </option>
            <option value="custom">
              {t("image_classification.predict_image_select.custom")}
            </option>
            <option value="custom_url">
              {t("image_classification.predict_image_select.custom_url")}
            </option>
            {generateOptions(
              "dog",
              6,
              "image_classification.predict_image_select.dog"
            )}
            {generateOptions(
              "cat",
              6,
              "image_classification.predict_image_select.cat"
            )}
          </Form.Select>

          {props.showUploadPredictImage && (
            <label htmlFor="file-input" className="custom-file-input">
              {t(
                "image_classification.predict_image_select.upload_file_button"
              )}
              <input
                id="file-input"
                style={{ display: "none" }}
                type="file"
                onChange={props.handleUploadPredictImage}
              />
            </label>
          )}
          {props.showEnterPredictImageURL && (
            <form
              id="img-url-form"
              onSubmit={props.handleEnterPredictImageURL}
              style={{ width: "100%", marginBottom: "10px" }}
            >
              <input
                id="img-url-input"
                type="text"
                placeholder={t(
                  "image_classification.predict_image_select.enter_url"
                )}
              />
              <button
                id="img-url-sbmt-btn"
                type="submit"
                className="custom-file-input"
              >
                {t(
                  "image_classification.predict_image_select.submit_url_button"
                )}
              </button>
            </form>
          )}
          {props.predictImage !== null && props.predictImage !== "" && (
            <img
              style={{ marginBottom: "16px" }}
              id="testImage"
              src={props.predictImage}
              height={224}
              width={224}
              alt={""}
            />
          )}

          {(!classList || classList.length < 1) &&
            props.predictImage !== null &&
            props.predictImage !== "" && (
              <div>{t("image_classification.train_before_predict")}</div>
            )}
          {classList && classList.length >= 2 && (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>{t("image_classification.table_name")}</th>
                  <th>{t("image_classification.table_probability")}</th>
                </tr>
              </thead>
              <tbody>
                {classList.map((item, index) => (
                  <tr key={index}>
                    <td>{item.className}</td>
                    <td>
                      {item.prediction && (
                        <ProgressBar
                          now={item.prediction}
                          label={`${item.prediction}%`}
                        />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Card.Body>
      </Card>
    </React.Fragment>
  );
}
