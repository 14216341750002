import CellComponent from "./CellComponent";
import React from "react";
import AgentComponent from "./AgentComponent";

function GridWorldComponent(props) {
  return [
    <AgentComponent key={"Agent"} x={props.x} y={props.y} />,
    props.levelMap.map((column, indexColumn) =>
      column.map((row, indexRow) => (
        <CellComponent
          key={"Cell" + indexRow + indexColumn}
          x={indexRow}
          y={indexColumn}
          value={row}
          qCell={props.qGrid[indexRow][indexColumn]}
          showQGrid={props.showQGrid}
          showPolicy={props.showPolicy}
        />
      ))
    ),
  ];
}

export default GridWorldComponent;
