/**
 * @license
 *
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { javascriptGenerator } from "blockly/javascript";
import {
  DATA_SELECTION_FIELD,
  ROOT_NODE_FIELD,
  LEFT_NODE_FIELD,
  RIGHT_NODE_FIELD,
  LEAF_NODE_FIELD,
  OPERATOR_FIELD,
} from "../../constants";

javascriptGenerator["data_set_type"] = function (block) {
  return (
    "setSelectedDataset('" + block.getFieldValue(DATA_SELECTION_FIELD) + "');\n"
  );
};

javascriptGenerator["leaf_type"] = function (block) {
  return "test('" + block.getFieldValue(LEAF_NODE_FIELD) + "');\n";
};

javascriptGenerator["node_type"] = function (block) {
  function loopOverNodes(blk) {
    function getNodeValue(node, fieldName) {
      const nodeResult = {};
      nodeResult["operator"] = [blk.getFieldValue(fieldName + OPERATOR_FIELD)];
      nodeResult["data"] = [blk.getFieldValue(fieldName)];

      if (node) {
        const childNode = node.getFieldValue(LEAF_NODE_FIELD);
        //nodeResult["subNodes"] = childNode || loopOverNodes(node); // equivalent to:
        nodeResult["subNodes"] = childNode
          ? [{ data: [childNode] }]
          : [loopOverNodes(node)];
      }

      return nodeResult;
    }
    const result = {};
    var childrenNodes = blk.getChildren(true);
    result["data"] = [blk.getFieldValue(ROOT_NODE_FIELD)];
    result["subNodes"] = [];
    result.subNodes.push(getNodeValue(childrenNodes[0], LEFT_NODE_FIELD));
    result.subNodes.push(getNodeValue(childrenNodes[1], RIGHT_NODE_FIELD));

    return result;
  }
  //console.log(JSON.stringify(loopOverNodes(block)));

  return "setTreeJSONData('" + JSON.stringify(loopOverNodes(block)) + "');\n";
};
