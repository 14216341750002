import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function IntroductionComponent(props) {
  const { t } = useTranslation();
  const showTaskResult = props.showIntroduction;
  const headline = props.headline;

  const size = true ? "lg" : "m";

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (showTaskResult) {
      handleShow();
    }
  }, [showTaskResult]);

  return (
    <Modal size={size} show={show}>
      <Modal.Header closeButton onClick={handleClose}>
        <Modal.Title>{headline}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={"modal-turtle-speech-bubble"}>
          <span className={`speech-bubble`}>
            Das Ziel des Agenten ist, das er selbständig den direktesten Pfad
            findet. Man bezeichnet ihn auch als optimalen Pfad. Der Agent kennt
            diesen aber zum Anfang noch nicht und diesen muss er über die Zeit
            durch ausprobieren lernen. Das erreicht der Agent in dem er die Welt
            Stück für Stück erkundet. Durch, das erkunden und ausprobieren lernt
            der Agent über die Zeit die beste Aktion zu wählen. Am Anfang geht
            der Agent Standardmäßig immer auf das obere Feld vom Agenten. War
            dieses nicht das Ziel so erhält der Agent auch keine Belohnung,
            sondern wird bestraft. Das merkt sich der Agent und sobald er wieder
            auf das Feld kommt, versucht er das nächste Feld nach dem
            Uhrzeigersinn (1. ↑, 2. →, 3. ↓, 4. ←). Falls der Agent das Ziel
            betreten hat, bekommt er eine Belohnung und wird wieder zurück zum
            Startpunkt geschickt. Durch die Belohnung weiß der Agent, das die
            Aktion sinnvoll gewesen ist und falls er nächstes mal wieder auf das
            Feld tritt, weiß er das dieses die beste Aktion war und wird diese
            wieder durchführen.
            <br />
            <img
              height={504 / 3}
              width={1684 / 3}
              src={"tutorial-q-learning-transparent.png"}
              alt={""}
            />
          </span>
          <img
            height={728 / 4}
            width={898 / 4}
            src={"/tutorial-turtle-transparent.png"}
            alt={""}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          {t("close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default IntroductionComponent;
