import React, {useEffect, useRef, useState} from "react";
import {Card, ProgressBar, Table} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import i18n from "../../i18n";

function WebcamComponent() {
    const {t} = useTranslation();

    const videoRef = useRef(null);

    useEffect(() => {
        if (!videoRef.current) return;
        let activeStream = null;

        const startVideo = async () => {
            try {
                const constraints = { video: true, width: 640, height: 480 };
                const stream = await navigator.mediaDevices.getUserMedia(constraints);
                activeStream = stream;
                videoRef.current.srcObject = stream;
            } catch (err) {
                console.error('Fehler beim Zugriff auf die Webcam:', err);
            }
        };

        startVideo();

        window.VIDEO = videoRef.current;
        window.videoPlaying = true;

        return () => {
            // Beenden des Streams, wenn das Level oder Kapitel verlassen wird.
            if (activeStream) {
                const tracks = activeStream.getTracks();
                tracks.forEach((track) => track.stop());
            }
        };
    }, []);


    function loadPredictionData() {
        const classNames = window.CLASS_NAMES ? [...window.CLASS_NAMES] : [];
        const predictions = window.PREDICTION_ARRAY ? [...window.PREDICTION_ARRAY] : [];

        const classPredictionList = classNames.map((className, index) => ({
            className: className,
            prediction: predictions[index] !== undefined ? Math.floor(predictions[index] * 100) : undefined,
        }));
        return classPredictionList;
    }

    const [classList, setClassList] = useState(loadPredictionData || []);

    useEffect(() => {
        const handleClassListChange = () => {
            setClassList(loadPredictionData());
        };

        window.addEventListener('refreshPredictions', handleClassListChange);

        return () => {
            window.removeEventListener('refreshPredictions', handleClassListChange);
        };
    }, []);

    return <Card style={{marginBottom: "16px"}}>
        <Card.Header>Webcam</Card.Header>
        <Card.Body>
            <video ref={videoRef} className="video" id="webcam" width="320" height="240" autoPlay muted />

            {classList && classList.length >= 2 &&
            <Table striped bordered hover>
                <thead>
                <tr>
                    <th>{t('image_classification.table_name')}</th>
                    <th>{t('image_classification.table_probability')}</th>
                </tr>
                </thead>
                <tbody>
                {classList.map((item, index) => (
                    <tr key={index}>
                        <td>{i18n.t("image_classification.blocks." + item.className)}</td>
                        <td>{item.prediction && <ProgressBar now={item.prediction} label={`${item.prediction}%`} />}</td>
                    </tr>
                ))}
                </tbody>
            </Table>}
        </Card.Body>
    </Card>;
}

export default WebcamComponent;
