/**
 * @license
 *
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import * as Blockly from "blockly/core";
import i18n from "../../i18n";
import { BLOCK_COLOR } from "../../constants";
import {
  getTargetColumn,
  getNodeAttributes,
  getDatasetMenu,
} from "../../components/DecisionTrees/DTHelper";

Blockly.Blocks["data_set_type"] = {
  init: function () {
    this.jsonInit({
      type: "data_set_type",
      message0: i18n.t("decision_trees.blocks.select_dataset"),
      nextStatement: null,
      colour: BLOCK_COLOR,
      tooltip: "",
      helpUrl: "",
      extensions: ["generate_dataset_menu"],
    });
  },
};

Blockly.Extensions.register("generate_dataset_menu", getDatasetMenu);

Blockly.Blocks["leaf_type"] = {
  init: function () {
    this.jsonInit({
      type: "leaf_type",
      message0: i18n.t("decision_trees.blocks.decision"),
      output: null,
      colour: 60,
      tooltip: "",
      helpUrl: "",
      extensions: ["leaf_extension"],
    });
  },
};

Blockly.Extensions.register("leaf_extension", getTargetColumn);

Blockly.Blocks["node_type"] = {
  init: function () {
    this.jsonInit({
      type: "node_type",
      message0: i18n.t("decision_trees.blocks.node_type"),
      args0: [
        {
          type: "input_dummy",
        },
        {
          type: "input_dummy",
        },
        {
          type: "input_dummy",
        },
        {
          type: "input_value",
          name: "left_node",
          check: "node_type",
        },
        {
          type: "input_dummy",
        },
        {
          type: "input_value",
          name: "right_node",
          check: "node_type",
        },
      ],
      output: null,
      previousStatement: null,
      colour: 195,
      tooltip: "",
      helpUrl: "",
      extensions: ["nodes_extension"],
    });
  },
};

Blockly.Extensions.register("nodes_extension", getNodeAttributes);
