import {
  BAD_FIELD_REWARD,
  GOAL_REWARD,
  PIXEL_PER_CELL,
  CELL_COLOR,
  GOAL_CELL_COLOR,
  BAD_CELL_COLOR,
  ACTION_TEXT_X_SHIFT,
  ACTION_TEXT_Y_SHIFT,
} from "../../constants";
import { getActionsFromPositionStringArray } from "../../model/GridWorld";
import { getCurrentPolicyFromField } from "../../model/QLearning";
import React from "react";

function CellComponent(props) {
  const x = props.x;
  const y = props.y;
  const value = props.value;
  const actionsRewards = props.qCell;
  let availableActions = getActionsFromPositionStringArray(x, y);

  let qValues = availableActions.map((action) => {
    if (!props.showQGrid) {
      return "";
    }

    if (action === "up") {
      return (
        <tspan
          key={x + y + action}
          x={ACTION_TEXT_X_SHIFT + PIXEL_PER_CELL * (x + 0.5)}
          y={ACTION_TEXT_Y_SHIFT + PIXEL_PER_CELL * (y + 0.25)}
        >
          {actionsRewards[0].toFixed(1)}
        </tspan>
      );
    } else if (action === "right") {
      return (
        <tspan
          key={x + y + action}
          x={ACTION_TEXT_X_SHIFT + PIXEL_PER_CELL * (x + 0.75)}
          y={ACTION_TEXT_Y_SHIFT + PIXEL_PER_CELL * (y + 0.5)}
        >
          {actionsRewards[1].toFixed(1)}
        </tspan>
      );
    } else if (action === "down") {
      return (
        <tspan
          key={x + y + action}
          x={ACTION_TEXT_X_SHIFT + PIXEL_PER_CELL * (x + 0.5)}
          y={ACTION_TEXT_Y_SHIFT + PIXEL_PER_CELL * (y + 0.75)}
        >
          {actionsRewards[2].toFixed(1)}
        </tspan>
      );
    } else if (action === "left") {
      return (
        <tspan
          key={x + y + action}
          x={ACTION_TEXT_X_SHIFT + PIXEL_PER_CELL * (x + 0.25)}
          y={ACTION_TEXT_Y_SHIFT + PIXEL_PER_CELL * (y + 0.5)}
        >
          {actionsRewards[3].toFixed(1)}
        </tspan>
      );
    }
    return "";
  });

  let icon;
  let policy = getCurrentPolicyFromField(x, y);
  if (policy === "up") {
    icon = "↑";
  }
  if (policy === "right") {
    icon = "→";
  }
  if (policy === "down") {
    icon = "↓";
  }
  if (policy === "left") {
    icon = "←";
  }
  if (!props.showPolicy) {
    icon = "";
  }

  let qInfos;
  if (value === 1 || value === 2) {
    qInfos = (
      <text
        key={"Text" + x + y}
        x={
          (value === 1 ? 2 * ACTION_TEXT_X_SHIFT : -2 * ACTION_TEXT_Y_SHIFT) +
          PIXEL_PER_CELL * (x + 0.5)
        }
        y={ACTION_TEXT_Y_SHIFT + PIXEL_PER_CELL * (y + 0.5)}
        fontWeight={"bold"}
        fontSize="16px"
      >
        {value === 1 ? BAD_FIELD_REWARD.toFixed(1) : GOAL_REWARD.toFixed(1)}
      </text>
    );
  } else {
    qInfos = (
      <text key={"Text" + x + y} fontWeight={"regular"} fontSize="13px">
        {qValues}
        <tspan
          key={"tspan" + x + y}
          fontWeight={"bold"}
          x={
            ACTION_TEXT_X_SHIFT +
            ACTION_TEXT_Y_SHIFT +
            PIXEL_PER_CELL * (x + 0.5)
          }
          y={ACTION_TEXT_Y_SHIFT + PIXEL_PER_CELL * (y + 0.5)}
        >
          {icon}
        </tspan>
      </text>
    );
  }

  return [
    value === 1 && (
      <image
        key={"Image" + x + y}
        href="gridworld/Fuchs.png"
        x={PIXEL_PER_CELL * x}
        y={PIXEL_PER_CELL * y}
        height={PIXEL_PER_CELL}
        width={PIXEL_PER_CELL}
      />
    ),
    value === 2 && (
      <image
        key={"Image" + x + y}
        href="gridworld/Karotte.png"
        x={PIXEL_PER_CELL * x}
        y={PIXEL_PER_CELL * y}
        height={PIXEL_PER_CELL}
        width={PIXEL_PER_CELL}
      />
    ),
    <rect
      key={"Rect" + x + y}
      x={PIXEL_PER_CELL * x}
      y={PIXEL_PER_CELL * y}
      width={PIXEL_PER_CELL}
      height={PIXEL_PER_CELL}
      stroke="black"
      fill={
        value === 1
          ? GOAL_CELL_COLOR
          : value === 2
          ? BAD_CELL_COLOR
          : CELL_COLOR
      }
      fillOpacity="0.5"
    />,
    qInfos,
  ];
}

export default CellComponent;
