import React, { useEffect, useState } from "react";
import { Col, Container, Dropdown, Row, ThemeProvider } from "react-bootstrap";
import { FlagIcon } from "react-flag-kit";
import i18n from "../../i18n";
import ImageClassificationComponent from "../ImageClassification/ImageClassificationComponent";
import GridWorldMainComponent from "../Gridworld/GridWorldMainComponent";
import DecisionTreesMainComponent from "../DecisionTrees/DecisionTreesMainComponent";
import { CopyrightInformationComponent } from "./CopyrightInformationComponent";
import { useTranslation } from "react-i18next";
import { startTF } from "../../tenserflow";
import OverviewCard from "./OverviewCard";
import "./Menu.css";
import MenuOption from "./MenuOption";
import EmptyLocalStorageButton from "./EmptyLocalStorageButton";
import { LegalNoticeInformationLink } from "./LegalNoticeInformationLink";

const availableLanguages = {
  en: { nativeName: "English", code: "US" },
  de: { nativeName: "Deutsch", code: "DE" },
};

function MenuComponent(props) {
  const [menu, setMenu] = useState("main");

  const { t } = useTranslation();

  const handleLangChange = (event) => {
    i18n.changeLanguage(event);
  };

  function navigate(chapter) {
    setMenu(() => {
      return chapter;
    });
  }

  useEffect(() => {
    if (menu === "imageClassification") {
      startTF();
    }
  }, [menu]);

  return (
    <React.Fragment>
      {menu === "main" && (
        <ThemeProvider
          breakpoints={["xxxl", "xxl", "xl", "lg", "md", "sm", "xs", "xxs"]}
          minBreakpoint="xxs"
        >
          <Container className="container">
            <Row>
              <Col xxs={2} className="logo">
                <a
                  href={t("overview.settings.institution_url")}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="/DEI_Logo.png" alt="Institution's logo" />
                </a>
              </Col>
              <Col xxs={2} className="logo text-end">
                <img src="/BBML_Logo.png" alt="Tool's logo" />
              </Col>
              <Col xxs={7} className="text-start">
                <h1>{t("overview.machine_learning")}</h1>
              </Col>
              <Col xxs={1} className="settings d-flex justify-content-between">
                <Dropdown onSelect={handleLangChange}>
                  <Dropdown.Toggle variant="light" id="language-dropdown">
                    <FlagIcon
                      code={availableLanguages[i18n.resolvedLanguage].code}
                    />{" "}
                    {availableLanguages[i18n.resolvedLanguage].nativeName}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {Object.keys(availableLanguages).map((language) => (
                      <Dropdown.Item eventKey={language} key={language}>
                        <FlagIcon code={availableLanguages[language].code} />{" "}
                        {availableLanguages[language].nativeName}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>
          </Container>
          <Container className="container">
            <div className="welcome-description">
              {t("overview.welcome_description")}
            </div>
            <Row>
              <Col>
                <OverviewCard
                  title="overview.decision_trees.title"
                  subtitle="overview.decision_trees.supervised_learning"
                  description="overview.decision_trees.description"
                  chapterLink="decisionTrees"
                  navigate={navigate}
                />
              </Col>
              <Col>
                <OverviewCard
                  title="overview.image_classification.title"
                  subtitle="overview.image_classification.supervised_learning"
                  description="overview.image_classification.description"
                  chapterLink="imageClassification"
                  navigate={navigate}
                />
              </Col>
              <Col>
                <OverviewCard
                  title="overview.game_world.title"
                  subtitle="overview.game_world.reinforcement_learning"
                  description="overview.game_world.description"
                  chapterLink="gridWorld"
                  navigate={navigate}
                />
              </Col>
            </Row>
          </Container>
          <div className="settings-buttons">
            <LegalNoticeInformationLink />
            <CopyrightInformationComponent />
            <EmptyLocalStorageButton />
          </div>
        </ThemeProvider>
      )}

      {menu === "imageClassification" && (
        <MenuOption
          title={t("overview.image_classification.title")}
          subAppName="ImageClassification"
          subApp={<ImageClassificationComponent />}
          t={t}
          navigate={navigate}
        />
      )}

      {menu === "gridWorld" && (
        <MenuOption
          title={t("overview.game_world.title")}
          subAppName="GridWorld"
          subApp={<GridWorldMainComponent />}
          t={t}
          navigate={navigate}
        />
      )}

      {menu === "decisionTrees" && (
        <MenuOption
          title={t("overview.decision_trees.title")}
          subAppName="DecisionTrees"
          subApp={<DecisionTreesMainComponent />}
          t={t}
          navigate={navigate}
        />
      )}
    </React.Fragment>
  );
}

export default MenuComponent;
