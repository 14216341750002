import React, { useEffect, useState } from "react";
import { javascriptGenerator } from "blockly/javascript";
import Interpreter from "js-interpreter";
import { Button } from "react-bootstrap";
import TaskResultComponent from "../Shared/TaskResultComponent";
import { useTranslation } from "react-i18next";
import DecisionTreeComponent from "./DecisionTreeComponent";
import DecisionTreeEvaluator from "./DecisionTreeEvaluator";
import DecisionTreeTable from "./DecisionTreeTable";
import data from "./DTData.json";
import {
  getselectedDataset,
  setselectedDataset,
  getBestPossibleAccuracy,
} from "../../constants";

function DecisionTreesMainComponent(props) {
  const { t } = useTranslation();
  const setTaskResult = props.setTaskResult;
  const taskResult = props.taskResult;
  const { primaryWorkspace } = props.blocklySettings;
  const handleChangeLevel = props.handleChangeLevel;
  let level = props.level;
  const [treeData, setTreeData] = useState();
  const [newLevel, setNewLevel] = useState(true);

  function handleNextLevel() {
    handleChangeLevel(level + 1);
    setTreeData(null);
    setNewLevel(true);
  }

  function hasNextLevel() {
    return level < 5;
  }

  function highlightBlock(id) {
    primaryWorkspace.current.highlightBlock(id);
  }

  const handleValueChange = (a) => {
    if (a > getBestPossibleAccuracy(level)) {
      setTaskResult({
        showTaskResult: true,
        isSuccessful: true,
        headline: t("decision_trees.success_title"),
        description: t("decision_trees.success_description"),
      });
    } else if (!newLevel) {
      setTaskResult({
        showTaskResult: true,
        isSuccessful: false,
        headline: t("decision_trees.failure_title"),
        description: t("decision_trees.failure_description"),
      });
    } else setNewLevel(false);
  };

  function test() {
    console.log("TEST");
  }

  function setSelectedDataset(selectedDataset) {
    setselectedDataset(selectedDataset);
  }

  function setTreeJSONData(data) {
    setTreeData(data);
  }

  useEffect(() => {
    //setTreeData(treeData);
  }, [treeData]);

  function initApi(interpreter, globalObject) {
    // Add an API function for the alert() block.
    var wrapper = function (text) {
      return alert(arguments.length ? text : "");
    };
    interpreter.setProperty(
      globalObject,
      "alert",
      interpreter.createNativeFunction(wrapper)
    );

    // Add an API function for the prompt() block.
    wrapper = function (text) {
      return prompt(text);
    };
    interpreter.setProperty(
      globalObject,
      "prompt",
      interpreter.createNativeFunction(wrapper)
    );

    wrapper = function () {
      return test();
    };
    interpreter.setProperty(
      globalObject,
      "test",
      interpreter.createNativeFunction(wrapper)
    );

    wrapper = function (param1) {
      return setSelectedDataset(param1);
    };
    interpreter.setProperty(
      globalObject,
      "setSelectedDataset",
      interpreter.createNativeFunction(wrapper)
    );

    wrapper = function (param1) {
      return setTreeJSONData(param1);
    };
    interpreter.setProperty(
      globalObject,
      "setTreeJSONData",
      interpreter.createNativeFunction(wrapper)
    );

    wrapper = function (id) {
      id = String(id || "");
      return highlightBlock(id);
    };
    interpreter.setProperty(
      globalObject,
      "highlightBlock",
      interpreter.createNativeFunction(wrapper)
    );
  }
  const generateCode = () => {
    setTimeout(() => {
      let code = javascriptGenerator.workspaceToCode(primaryWorkspace.current);

      let myInterpreter = new Interpreter(code, initApi);

      function nextStep() {
        if (myInterpreter.step()) {
          setTimeout(nextStep, 2);
        } else {
          highlightBlock(null);
        }
      }

      //myInterpreter.run();
      nextStep();
    }, 100);
    setTaskResult({
      showTaskResult: false,
      isSuccessful: false,
      headline: t("decision_trees.failure_title"),
      description: t("decision_trees.failure_description"),
    });
  };

  return (
    <React.Fragment>
      <div className="evaluation-button">
        <Button variant="success" onClick={generateCode}>
          {t("decision_trees.evaluate_tree")}
        </Button>
      </div>
      <div className="decision-trees">
        {treeData && (
          <div>
            <h4>Decision Tree</h4>
            <DecisionTreeComponent treeData={JSON.parse(treeData)} />
            <div>
              <DecisionTreeEvaluator
                tree={JSON.parse(treeData)}
                dataset={data[getselectedDataset()]}
                onValueChange={handleValueChange}
              />
            </div>
          </div>
        )}
        <div>
          <div className="decision-table">
            <h4>Decision Table</h4>
            <DecisionTreeTable data={data[getselectedDataset()]} />
          </div>
          <TaskResultComponent
            showTaskResult={taskResult.showTaskResult}
            isSuccessful={taskResult.isSuccessful}
            headline={taskResult.headline}
            description={taskResult.description}
            hasNextLevel={hasNextLevel()}
            handleNextLevel={handleNextLevel}
          />
        </div>
      </div>
    </React.Fragment>
  );
}

export default DecisionTreesMainComponent;
