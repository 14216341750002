import { updateAgentManually } from "./model/Agent";
import {
  calculateBestAction,
  setParameter,
  updateQValue,
  calculateTDReward,
  moveAgent,
} from "./model/QLearning";

let primaryWorkspace;

export function bindWorkspace(workspace) {
  primaryWorkspace = workspace;
}

function initializeModel(
  customLearningRate,
  customDiscountFactor,
  customExplorationRate
) {
  setParameter(customLearningRate, customDiscountFactor, customExplorationRate);
}

function chooseBestAction() {
  calculateBestAction();
}

function doBestAction() {
  moveAgent();
}

function calculateReward() {
  calculateTDReward();
}

function updateModel(episode = 0) {
  updateQValue(episode);
}

function updateReward(action) {
  updateAgentManually(action);
}

/*
function useAgentWithoutBlockly(action) {
    initializeModel();

    for(let i = 0; i < 1000; i++) {
        chooseBestAction();
        doBestAction();
        calculateReward();
        updateModel(i);
    }
}*/

function highlightBlock(id) {
  primaryWorkspace.current.highlightBlock(id);
}

export function initApi(interpreter, globalObject) {
  // Add an API function for the alert() block.
  var wrapper = function (text) {
    return alert(arguments.length ? text : "");
  };
  interpreter.setProperty(
    globalObject,
    "alert",
    interpreter.createNativeFunction(wrapper)
  );

  // Add an API function for the prompt() block.
  wrapper = function (text) {
    return prompt(text);
  };
  interpreter.setProperty(
    globalObject,
    "prompt",
    interpreter.createNativeFunction(wrapper)
  );

  wrapper = function (learningRate, discountFactor, explorationRate) {
    return initializeModel(learningRate, discountFactor, explorationRate);
  };
  interpreter.setProperty(
    globalObject,
    "initializeModelExtended",
    interpreter.createNativeFunction(wrapper)
  );

  wrapper = function (text) {
    initializeModel();
  };
  interpreter.setProperty(
    globalObject,
    "initializeModel",
    interpreter.createNativeFunction(wrapper)
  );

  wrapper = function (text) {
    chooseBestAction();
  };
  interpreter.setProperty(
    globalObject,
    "chooseBestAction",
    interpreter.createNativeFunction(wrapper)
  );

  wrapper = function (text) {
    doBestAction();
  };
  interpreter.setProperty(
    globalObject,
    "doBestAction",
    interpreter.createNativeFunction(wrapper)
  );

  wrapper = function (text) {
    calculateReward();
  };
  interpreter.setProperty(
    globalObject,
    "calculateReward",
    interpreter.createNativeFunction(wrapper)
  );

  wrapper = function (text) {
    updateModel();
  };
  interpreter.setProperty(
    globalObject,
    "updateModel",
    interpreter.createNativeFunction(wrapper)
  );

  wrapper = function (text) {
    updateReward("up");
  };
  interpreter.setProperty(
    globalObject,
    "agentGoUp",
    interpreter.createNativeFunction(wrapper)
  );

  wrapper = function (text) {
    updateReward("down");
  };
  interpreter.setProperty(
    globalObject,
    "agentGoDown",
    interpreter.createNativeFunction(wrapper)
  );

  wrapper = function (text) {
    updateReward("left");
  };
  interpreter.setProperty(
    globalObject,
    "agentGoLeft",
    interpreter.createNativeFunction(wrapper)
  );

  wrapper = function (text) {
    updateReward("right");
  };
  interpreter.setProperty(
    globalObject,
    "agentGoRight",
    interpreter.createNativeFunction(wrapper)
  );

  wrapper = function (id) {
    id = String(id || "");
    return highlightBlock(id);
  };
  interpreter.setProperty(
    globalObject,
    "highlightBlock",
    interpreter.createNativeFunction(wrapper)
  );
}
