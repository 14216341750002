import React, { useEffect, useState } from "react";
import {
  clearTaskResult,
  FAST,
  getBestPossibleReward,
  getInitialAgent,
  getMap,
  MAP,
} from "../../constants";
import {
  bindAgent,
  getCurrentAgentReward,
  resetAgent,
} from "../../model/Agent";
import { checkPolicy } from "../../model/QLearning";
import { bindQTable, setGridWorldLevel } from "../../model/GridWorld";
import { bindWorkspace, initApi } from "../../functions";
import { javascriptGenerator } from "blockly/javascript";
import Interpreter from "js-interpreter";
import GridWorldComponent from "./GridWorldComponent";
import { Button } from "react-bootstrap";
import TaskResultComponent from "../Shared/TaskResultComponent";
import { useTranslation } from "react-i18next";

function GridWorldMainComponent(props) {
  const { t } = useTranslation();

  const setTaskResult = props.setTaskResult;
  const taskResult = props.taskResult;

  const { primaryWorkspace } = props.blocklySettings;

  const handleChangeLevel = props.handleChangeLevel;

  const [agent, setAgent] = useState(getInitialAgent(1));
  const [qGrid, setQGrid] = useState(initializeQGrid());
  const [showQGrid, setShowQGrid] = useState(false);
  const [showPolicy, setShowPolicy] = useState(false);

  const [map, setMap] = useState(getMap(1));

  let level = props.level;

  function initializeQGrid() {
    let qTable = JSON.parse(JSON.stringify(MAP));

    qTable.map((column, indexColumn) =>
      column.map(
        (row, indexRow) => (qTable[indexColumn][indexRow] = [0, 0, 0, 0])
      )
    );
    return qTable;
  }

  // check for successfull task
  function checkTaskSuccessful(level) {
    if (level <= 4) {
      let reward = getCurrentAgentReward();
      if (reward >= getBestPossibleReward(level)) {
        return {
          showTaskResult: true,
          isSuccessful: true,
          headline: t("grid_world.reached_goal_title"),
          description: t("grid_world.reached_goal_description"),
        };
      } else if (reward > 0) {
        return {
          showTaskResult: true,
          isSuccessful: false,
          headline: t("grid_world.almost_reached_goal_title"),
          description: t("grid_world.almost_reached_goal_description"),
        };
      } else {
        return {
          showTaskResult: true,
          isSuccessful: false,
          headline: t("grid_world.lose_title"),
          description: t("grid_world.lose_description"),
        };
      }
    } else {
      if (checkPolicy(level)) {
        return {
          showTaskResult: true,
          isSuccessful: true,
          headline: t("grid_world.great_policy_title"),
          description: t("grid_world.great_policy_description"),
        };
      } else {
        return {
          showTaskResult: true,
          isSuccessful: false,
          headline: t("grid_world.lose_policy_title"),
          description: t("grid_world.lose_policy_description"),
        };
      }
    }
  }

  const generateCode = () => {
    function clearGridWorld() {
      setAgent(getInitialAgent(level));
    }

    clearGridWorld();

    setTaskResult(clearTaskResult());

    bindAgent(agent, setAgent);
    bindQTable(qGrid, setQGrid);
    bindWorkspace(primaryWorkspace);

    setAgent(() => {
      //send agent back to the start position
      resetAgent(getInitialAgent(level));
      return getInitialAgent(level);
    });

    var code = javascriptGenerator.workspaceToCode(primaryWorkspace.current);

    let myInterpreter = new Interpreter(code, initApi);

    if (!FAST) {
      function nextStep() {
        if (myInterpreter.step()) {
          setTimeout(nextStep, 2);
        } else {
          primaryWorkspace.current.highlightBlock(null);
          setTaskResult(checkTaskSuccessful(level));
        }
      }

      nextStep();
    } else {
      myInterpreter.run();
      setTaskResult(checkTaskSuccessful(level));
    }
  };

  function hasNextLevel() {
    return level < 6;
  }

  function handleNextLevel() {
    handleChangeLevel(level + 1);
  }

  useEffect(() => {
    setShowPolicy(false);
    setShowQGrid(false);

    if (level > 4) {
      setShowPolicy(true);
      setQGrid(initializeQGrid());
    }
    if (level > 5) {
      setShowQGrid(true);
    }
    setMap(getMap(level));
    setAgent(getInitialAgent(level));
    setGridWorldLevel(level);
  }, [props.handleChangeLevel, level]);

  return (
    <React.Fragment>
      <div className={"grid-world"}>
        <svg
          width={level === 1 ? "320px" : "400px"}
          height={level === 1 ? "320px" : "400px"}
          xmlns="http://www.w3.org/2000/svg"
        >
          <GridWorldComponent
            levelMap={map}
            x={agent.x}
            y={agent.y}
            showQGrid={showQGrid}
            qGrid={qGrid}
            showPolicy={showPolicy}
          />
        </svg>
      </div>
      <div>
        <Button variant="success" onClick={generateCode}>
          {t("grid_world.run_agent")}
        </Button>
      </div>
      <div>
        <TaskResultComponent
          showTaskResult={taskResult.showTaskResult}
          isSuccessful={taskResult.isSuccessful}
          headline={taskResult.headline}
          description={taskResult.description}
          hasNextLevel={hasNextLevel()}
          handleNextLevel={handleNextLevel}
        />
      </div>
      {level <= 4 && (
        <div>
          {t("grid_world.your_reward")} {agent.reward.toFixed(1)} <br />
          <small>{t("grid_world.field_description")}</small>
        </div>
      )}
    </React.Fragment>
  );
}
export default GridWorldMainComponent;
