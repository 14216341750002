import {BAD_FIELD_REWARD, GOAL_REWARD, VISIT_REWARD, G, B} from "../constants";
import {getCellType} from "./GridWorld";

let currentAgentX;
let currentAgentY;
let nextAgentX;
let nextAgentY;
let agentReward;

let setAgent;

export function bindAgent(agent, updateAgent){
    currentAgentX = agent.x;
    currentAgentY = agent.y;
    agentReward = agent.reward;
    setAgent = updateAgent;
}
export function resetAgent(agent){
    currentAgentX = agent.x;
    currentAgentY = agent.y;
    agentReward = agent.reward;
}

export function getCurrentAgentPosition(){
    return {x: currentAgentX, y: currentAgentY};
}

export function setNextAgentPosition(x, y){
    nextAgentX = x;
    nextAgentY = y;
}

export function getNextAgentPosition(){
    return {x: nextAgentX, y: nextAgentY};
}

export function updateAgent(episode){
    setAgent(agent => {
        let reward = episode;

        return ({x: nextAgentX, y: nextAgentY, reward: reward})
    });
    currentAgentX = nextAgentX;
    currentAgentY = nextAgentY;
}

export function updateAgentManually(action){
    setAgent(agent => {
        let x = agent.x;
        let y = agent.y;
        let reward = agent.reward + VISIT_REWARD;

        if(action === "up"){
            y-= 1;
        }else if(action === "down"){
            y+= 1;
        }else if(action === "left"){
            x-= 1;
        }else if(action === "right"){
            x+= 1;
        }

        if(getCellType(x,y) === G){
            agentReward = reward + GOAL_REWARD;
            return({x: x, y: y, reward: reward + GOAL_REWARD});
        } else if(getCellType(x,y) === B){
            agentReward = reward + BAD_FIELD_REWARD;
            return({x: x, y: y, reward: reward + BAD_FIELD_REWARD});
        } else {
            agentReward = reward;
            return({x: x, y: y, reward: reward});
        }
    })
}

export function getCurrentAgentReward(){
    return agentReward;
}
