import React from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const EmptyLocalStorageButton = () => {
  const { t } = useTranslation();

  const emptyLocalStorage = () => {
    const item = localStorage.getItem("customPredictImage");
    if (item) {
      localStorage.removeItem("customPredictImage");
      alert(t("overview.settings.delete_successful"));
    } else {
      alert(t("overview.settings.delete_no_data"));
    }
  };

  return (
    <Button
      type="button"
      className="btn btn-danger"
      onClick={emptyLocalStorage}
    >
      {t("overview.settings.empty_local_storage_button")}
    </Button>
  );
};

export default EmptyLocalStorageButton;
