/**
 * @license
 *
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { javascriptGenerator } from "blockly/javascript";
import i18n from "../../i18n";

javascriptGenerator["train"] = function (block) {
  return "trainModel();\n";
};

javascriptGenerator["wait_seconds"] = function (block) {
  const number_seconds = block.getFieldValue("seconds");
  return "waitForSeconds(" + number_seconds + ");\n";
};

javascriptGenerator["create_screenshot"] = function (block) {
  const dropdown_class = block.getFieldValue("class");
  return 'createScreenshotFor("' + dropdown_class + '");\n';
};

javascriptGenerator["webcam_predict"] = function (block) {
  return "webcamPredict();\n";
};

javascriptGenerator["dogs"] = function (block) {
  return "CLASS_NAME_".concat(i18n.t("image_classification.blocks.dog") + "\n");
};

javascriptGenerator["cats"] = function (block) {
  return "CLASS_NAME_".concat(i18n.t("image_classification.blocks.cat") + "\n");
};

javascriptGenerator["class_custom"] = function (block) {
  return "CLASS_NAME_" + block.getFieldValue("NAME") + "\n";
};

javascriptGenerator["image_custom"] = function (block) {
  return block.getFieldValue("IMAGE_ID") + "\n";
};
for (let i = 1; i < 7; i++) {
  javascriptGenerator["image_dog" + i] = function (block) {
    return block.getFieldValue("IMAGE_ID") + "\n";
  };
  javascriptGenerator["image_cat" + i] = function (block) {
    return block.getFieldValue("IMAGE_ID") + "\n";
  };
}

javascriptGenerator["cnn_settings"] = function (block) {
  return (
    "cnnConfig#" +
    block.getFieldValue("epochs") +
    "#" +
    block.getFieldValue("batchSize") +
    "#" +
    block.getFieldValue("learningRate") +
    "\n"
  );
};
