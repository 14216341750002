import React from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export function LegalNoticeInformationLink() {
  const { t } = useTranslation();

  return (
    <Button type="button" className="imprint-button btn-secondary">
      <a
        href={t("overview.settings.imprint_url")}
        target="_blank"
        rel="noopener noreferrer"
      >
        {t("overview.settings.imprint")}
      </a>
    </Button>
  );
}
