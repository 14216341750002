import React from "react";
import "./LevelProgressBarComponent.css";
import LevelComponent from "./LevelComponent";

function LevelProgressBarComponent(props) {
  const currentLevel = props.currentLevel;
  const maxLevel = props.maxLevel;

  const levels = [];
  for (let i = 1; i <= maxLevel; i++) {
    levels.push(
      <LevelComponent
        key={i}
        isCurrentLevel={i === currentLevel}
        level={i}
        handleChangeLevel={props.handleChangeLevel}
      />
    );
  }

  return <div className={"level-progress-bar"}>{levels}</div>;
}

export default LevelProgressBarComponent;
