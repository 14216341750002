import React from "react";
import Card from "react-bootstrap/Card";
import { useTranslation } from "react-i18next";

const OverviewCard = ({
  title,
  subtitle,
  description,
  chapterLink,
  navigate,
}) => {
  const { t } = useTranslation();
  return (
    <Card
      className="overview-card card-border"
      onClick={() => navigate(chapterLink)}
    >
      <Card.Body>
        <Card.Title className="text-uppercase font-weight-bold mb-3">
          {t(title)}
        </Card.Title>
        <Card.Subtitle className="mb-2 text-muted">{t(subtitle)}</Card.Subtitle>
        <Card.Text className="card-text-hidden">{t(description)}</Card.Text>
      </Card.Body>
    </Card>
  );
};

export default OverviewCard;
