/**
 * @license
 *
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { javascriptGenerator } from "blockly/javascript";

// These following blocks are used to move the agent with the Q-Learning algorithm:

javascriptGenerator["initialize_model"] = function (block) {
  return "initializeModel();\n";
};

javascriptGenerator["initialize_model_extended"] = function (block) {
  return (
    "initializeModelExtended(" +
    block.getFieldValue("learningRate") +
    ", " +
    block.getFieldValue("discountFactor") +
    ", " +
    block.getFieldValue("explorationRate") +
    ");\n"
  );
};

javascriptGenerator["choose_best_action"] = function (block) {
  return "chooseBestAction();\n";
};

javascriptGenerator["do_best_action"] = function (block) {
  return "doBestAction();\n";
};

javascriptGenerator["calculate_reward"] = function (block) {
  return "calculateReward();\n";
};

javascriptGenerator["update_model"] = function (block) {
  return "updateModel();\n";
};

// These following blocks are used to move the agent manually.

javascriptGenerator["agent_go_up"] = function (block) {
  return "agentGoUp();\n";
};

javascriptGenerator["agent_go_down"] = function (block) {
  return "agentGoDown();\n";
};

javascriptGenerator["agent_go_left"] = function (block) {
  return "agentGoLeft();\n";
};

javascriptGenerator["agent_go_right"] = function (block) {
  return "agentGoRight();\n";
};
