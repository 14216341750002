import React from "react";
import NodeComponent from "./NodeComponent";
import "./DecisionTree.css";

const DecisionTreeComponent = ({ treeData }) => {
  if (!treeData.subNodes || treeData.subNodes.length === 0) {
    return null;
  }

  return (
    <div className="tree">
      <ul>
        <NodeComponent node={treeData} />
      </ul>
    </div>
  );
};

export default DecisionTreeComponent;
