import React from "react";
import { PIXEL_PER_CELL } from "../../constants";

function AgentComponent(props) {
  const x = PIXEL_PER_CELL * (props.x + 0.5);
  const y = PIXEL_PER_CELL * (props.y + 0.5);
  const radius = PIXEL_PER_CELL * 0.5;
  return [
    <circle
      key={"Circle" + x + y}
      cx={x}
      cy={y}
      r={radius}
      fill="blue"
      fillOpacity="0.5"
    />,
    <image
      key={"Image" + x + y}
      href="gridworld/Hase.png"
      x={x - radius}
      y={y - radius}
      height={PIXEL_PER_CELL}
      width={PIXEL_PER_CELL}
    />,
  ];
}

export default AgentComponent;
