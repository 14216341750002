import {getCurrentAgentPosition, getNextAgentPosition} from "./Agent";
import {getColumnSize, getMap, getRowSize, MAP, O} from "../constants";

let qTable;
let setQGrid;
let qTableReady = false;
let level = 1;

export function setGridWorldLevel(currentLevel) {
    level = currentLevel;
}

export function bindQTable(qGrid, updateQTable){
    qTable = qGrid;
    setQGrid = updateQTable;
    qTableReady = true;
}

export function isQTableReady() {
    return qTableReady;
}

function removeBorder(x,y,availableActions) {
    if (x === 0 || y === 0 || x === MAP.length - 1 || y === MAP[0].length - 1) {
        if (x === 0) {
            let index = availableActions.indexOf("left");
            if (index > -1) {
                availableActions.splice(index, 1);
            }
        }
        if (y === 0) {
            let index = availableActions.indexOf("up");
            if (index > -1) {
                availableActions.splice(index, 1);
            }
        }
        if (x === MAP.length - 1) {
            let index = availableActions.indexOf("right");
            if (index > -1) {
                availableActions.splice(index, 1);
            }
        }
        if (y === MAP.length - 1) {
            let index = availableActions.indexOf("down");
            if (index > -1) {
                availableActions.splice(index, 1);
            }
        }
    }
}

export function getActionsFromPosition(x,y){
    let availableActions = ["up", "right", "down", "left"];

    removeBorder(x,y,availableActions);

    return availableActions;
}

export function getActionsFromPositionStringArray(x,y) {
    let availableActions = getActionsFromPosition(x,y);
    let actionsStringArray = [];
    for(let i = 0; i < availableActions.length; i++){
        actionsStringArray.push(availableActions[i]);
    }
    return actionsStringArray;
}

export function getCurrentActions(){
    let currentAgent = getCurrentAgentPosition();

    return getActionsFromPosition(currentAgent.x, currentAgent.y);
}

export function getNextActions(){
    let nextAgent = getNextAgentPosition();
    return getActionsFromPosition(nextAgent.x, nextAgent.y);
}

export function getCurrentActionValues(){
    let currentAgent = getCurrentAgentPosition();
    return qTable[currentAgent.x][currentAgent.y];
}

export function getNextActionValues(){
    let nextAgent = getNextAgentPosition();
    return qTable[nextAgent.x][nextAgent.y];
}

export function getCurrentActionFromValue(value){
    let action = getCurrentActionValues().indexOf(value);
    if(action === 0){
        return "up";
    }
    if(action === 1){
        return "right";
    }
    if(action === 2){
        return "down";
    }
    if(action === 3){
        return "left";
    }
    return undefined;
}

export function getValueFromPositionAndAction(x,y,action){
    return qTable[x][y][getIndexFromAction(action)];
}

export function getCurrentValueFromAction(action){
    let currentAgent = getCurrentAgentPosition();
    return getValueFromPositionAndAction(currentAgent.x,currentAgent.y,action);
}

function getIndexFromAction(action){
    if(action === "up"){
        return 0;
    }
    if(action === "right"){
        return 1;
    }
    if(action === "down"){
        return 2;
    }
    if(action === "left"){
        return 3;
    }
    return undefined;
}

export function updateCurrentAction(action, value){
    let currentAgent = getCurrentAgentPosition();
    qTable[currentAgent.x][currentAgent.y][getIndexFromAction(action)] = value;
}
export function updateQTable() {
    setQGrid(qGrid =>{
        return qTable;
    });
}

export function getCellType(x, y) {
    let currentMap = getMap(level);
    if(x > getRowSize(level) || x < 0 || y > getColumnSize(level) || y < 0){
        return O;
    }
    return currentMap[y][x];
}

