import { Block, Field, Shadow, Value } from "./components/Shared";
import React from "react";

import i18n from "./i18n";
import config from "./mainConfig.json";

export const PIXEL_PER_CELL = 80;
export const VISIT_REWARD = -0.1;
export const BAD_FIELD_REWARD = -10;
export const GOAL_REWARD = 1;
export const CELL_COLOR = "white";
export const GOAL_CELL_COLOR = "rgb(255, 0, 0)";
export const BAD_CELL_COLOR = "rgb(0, 255, 0)";
export const ACTION_TEXT_X_SHIFT = -10;
export const ACTION_TEXT_Y_SHIFT = 6;
export const LEARNING_RATE = 0.6;
export const DISCOUNT_FACTOR = 0.5;
export const EXPLORATION_RATE = 0.1;
export const BLOCK_COLOR = 230;
export const CLASS_BLOCK_COLOR = 45;
export const IMAGE_BLOCK_SIZE = 80;
export const IMAGE_CLASSIFICATION_PATH = "/imageClassification/images";
export const FAST = false;
export const DATA_SELECTION_FIELD = "DATA_SELECTION";
export const ROOT_NODE_FIELD = "ROOT_NODE";
export const LEFT_NODE_FIELD = "LEFT_NODE";
export const RIGHT_NODE_FIELD = "RIGHT_NODE";
export const LEAF_NODE_FIELD = "LEAF_NODE";
export const OPERATOR_FIELD = "_OPERATOR";
export const DECISION_COLUMN_FIELD = "DECISION_COLUMN";

export const N = 0; // Normal-field
export const B = 1; // Bad-field
export const G = 2; // Goal-field
export const W = 3; // Wall
export const O = 4; // outside the grid

let DTSelectedDataset = "";

export function setselectedDataset(d) {
  DTSelectedDataset = d;
}

export function getselectedDataset() {
  return DTSelectedDataset;
}

export const MAP = [
  [B, N, N, N, B],
  [N, N, N, N, N],
  [N, N, G, B, W],
  [N, N, N, N, N],
  [N, N, N, N, N],
];

var blocks = {
  DecisionTrees: [
    <Block key="node_type" type="node_type" />,
    <Block key="leaf_type" type="leaf_type" />,
  ],
  GridWorld: [
    <Block key="agent_go_up" type="agent_go_up" />,
    <Block key="agent_go_down" type="agent_go_down" />,
    <Block key="agent_go_left" type="agent_go_left" />,
    <Block key="agent_go_right" type="agent_go_right" />,
  ],
  ImageClassification: [],
};

function getRepeatBlock(n) {
  return (
    <Block key="controls_repeat_ext" type="controls_repeat_ext">
      <Value name="TIMES">
        <Shadow type="math_number">
          <Field name="NUM">{n}</Field>
        </Shadow>
      </Value>
    </Block>
  );
}

function getAgentActionBlocks() {
  return [
    <Block key="choose_best_action" type="choose_best_action" />,
    <Block key="do_best_action" type="do_best_action" />,
    <Block key="calculate_reward" type="calculate_reward" />,
    <Block key="update_model" type="update_model" />,
  ];
}

export function getMainConfig() {
  Object.keys(config).forEach((chapter) => {
    config[chapter] = config[chapter].map((item) => ({
      ...item,
      taskTranslatedDescription: item.taskDescription
        ? i18n.t(item.taskDescription)
        : item.taskDescription,
    }));
  });
  return config;
}

export function getMaxLevel(chapterName) {
  const level = getMainConfig()[chapterName];
  if (level) {
    return level.length;
  }

  throw new Error(
    "No subApp with chapter name '" +
      chapterName +
      "' was found in main config. "
  );
}

export function getBestPossibleReward(level) {
  if (level === 1) {
    return 0.7;
  }
  if (level === 2) {
    return 0.6;
  }
  return 0.4;
}

export function getBestPossibleAccuracy(level) {
  if (level < 3) {
    return 0.99;
  }
  if (level === 4) {
    return 0.8;
  }
  return 0.7;
}

export function getColumnSize(level) {
  return getMap(level).length - 1;
}

export function getRowSize(level) {
  return getMap(level)[0].length - 1;
}

export function getMap(level) {
  if (level === 1) {
    return [
      [N, N, N, N],
      [N, N, G, N],
      [N, N, N, N],
      [N, N, N, N],
    ];
  } else if (level === 3) {
    return [
      [B, N, N, N, B],
      [N, N, N, N, N],
      [N, N, G, B, W],
      [N, N, B, N, N],
      [N, N, N, N, N],
    ];
  } else {
    return [
      [B, N, N, N, B],
      [N, N, N, N, N],
      [N, N, G, B, W],
      [N, N, N, N, N],
      [N, N, N, N, N],
    ];
  }
}

export function clearTaskResult() {
  return {
    showTaskResult: false,
    isSuccessful: undefined,
    headline: undefined,
    description: undefined,
  };
}

export function getInitialAgent(level) {
  if (level === 1) {
    return { x: 1, y: 3, reward: 0 };
  } else {
    return { x: 0, y: 4, reward: 0 };
  }
}

export function getTaskDescription(chapterName, level) {
  const currentLevel = getMainConfig()[chapterName].find(
    (item) => item.level === level
  );
  return currentLevel
    ? currentLevel.taskTranslatedDescription
    : i18n.t("overview.no_description");
}

const catsAndDogsSolution = `<xml>
        <block type="dogs" x="200" y="10">
            <next>
                <block type="image_dog1">
                    <next>
                        <block type="image_dog2">
                            <next>
                                <block type="image_dog3">
                                    <next>
                                        <block type="image_dog4">
                                            <next>
                                                <block type="image_dog5">
                                                    <next>
                                                        <block type="image_dog6"></block>
                                                    </next>
                                                </block>
                                            </next>
                                        </block>
                                    </next>
                                </block>
                            </next>
                        </block>
                    </next>
                </block>
            </next>
        </block>
        <block type="cats" x="500" y="10">
            <next>
                <block type="image_cat1">
                    <next>
                        <block type="image_cat2">
                            <next>
                                <block type="image_cat3">
                                    <next>
                                        <block type="image_cat4">
                                            <next>
                                                <block type="image_cat5">
                                                    <next>
                                                        <block type="image_cat6"></block>
                                                    </next>
                                                </block>
                                            </next>
                                        </block>
                                    </next>
                                </block>
                            </next>
                        </block>
                    </next>
                </block>
            </next>
        </block>
    </xml>
`;

export function getInitialXml(subAppName, level, instructorMode) {
  if (!instructorMode) {
    if (subAppName === "ImageClassification") {
      let blockBody = `<block type="dogs" x="200" y="10">
                        <next>
                            <block type="image_dog6"></block>
                        </next>
                    </block>
                    <block type="cats" x="500" y="10">
                    </block>`;
      if (level === 5) {
        blockBody += `<block type="cnn_settings" x="10" y="10">
                    </block>`;
      }
      if (level < 6) {
        return `
        <xml xmlns="http://www.w3.org/1999/xhtml">
        ${blockBody}
        </xml>
          `;
      }
    }
    if (subAppName === "DecisionTrees" && level < 4) {
      let blockBody = `<block type="node_type"></block>`;

      if (level < 2)
        blockBody += `<block type="leaf_type" x="200" y="140"></block>`;

      if (level < 3)
        blockBody += `<block type="leaf_type" x="200" y="90"></block>`;

      return `
      <xml xmlns="http://www.w3.org/1999/xhtml">
        ${blockBody}
      </xml>
        `;
    }
    return '<xml xmlns="http://www.w3.org/1999/xhtml"></xml>';
  }

  if (subAppName === "DecisionTrees") {
    function getDTBlockBody(
      leftNodeValue,
      rightNodeValue,
      leftLeafValue,
      rightLeafValue,
      rootNodeValue = "",
      leftNodeOperator = "",
      rightNodeOperator = ""
    ) {
      return `
        <block type="node_type">
          <field name="ROOT_NODE">${rootNodeValue}</field>
          <field name="LEFT_NODE">${leftNodeValue}</field>
          <field name="RIGHT_NODE">${rightNodeValue}</field>
          <field name="LEFT_NODE_OPERATOR">${leftNodeOperator}</field>
          <field name="RIGHT_NODE_OPERATOR">${rightNodeOperator}</field>
          <value name="left_node">
            <block type="leaf_type">
              <field name="LEAF_NODE">${leftLeafValue}</field>
            </block>
          </value>
          <value name="right_node">
            <block type="leaf_type">
              <field name="LEAF_NODE">${rightLeafValue}</field>
            </block>
          </value>
        </block>`;
    }

    function getNestedNodeBlockBody(
      leftNodeValue,
      rightNodeValue,
      leftSubNodeBlock,
      rightSubNodeBlock,
      rootNodeValue = ""
    ) {
      return `
        <block type="node_type">
          <field name="ROOT_NODE">${rootNodeValue}</field>
          <field name="LEFT_NODE">${leftNodeValue}</field>
          <field name="RIGHT_NODE">${rightNodeValue}</field>
          <value name="left_node">
            ${leftSubNodeBlock}
          </value>
          <value name="right_node">
            ${rightSubNodeBlock}
          </value>
        </block>`;
    }

    let blockBody = null;
    if (level === 1) blockBody = getDTBlockBody(2, 4, "Motorcycle", "Car");
    else if (level === 2)
      blockBody = getDTBlockBody("Rain", "Sun", "No", "Yes");
    else if (level === 3)
      blockBody = getDTBlockBody("Cool", "Hot", "Yes", "No", "Temp");
    else {
      const leftSubNodeBlock =
        level === 4
          ? getDTBlockBody("Cool", "Hot", "Yes", "No", "Temp")
          : getDTBlockBody(21, 15, "No", "Yes", "Temp", "&gt;=", "&lt;=");
      const rightSubNodeBlock = getDTBlockBody(
        "Normal",
        "High",
        "Yes",
        "No",
        "Humidity"
      );

      blockBody = getNestedNodeBlockBody(
        "Rain",
        "Sun",
        leftSubNodeBlock,
        rightSubNodeBlock
      );
    }

    let blockWrapper =
      level > 5
        ? `<block type="data_set_type" x="20" y="20">
          <next>
            ${blockBody}
          </next>
        </block>`
        : blockBody;

    return `
    <xml xmlns="http://www.w3.org/1999/xhtml">
    ${blockWrapper}
    </xml>
      `;
  } else if (subAppName === "GridWorld") {
    if (level === 1) {
      return `
        <xml xmlns="http://www.w3.org/1999/xhtml">
        <block type="agent_go_up" x="20" y="20">
        <next>
            <block type="agent_go_up">
            <next>
                <block type="agent_go_right">
                </block>
            </next>
            </block>
        </next>
        </block>
        </xml>
          `;
    } else if (level <= 4) {
      return `
        <xml xmlns="http://www.w3.org/1999/xhtml">
        <block type="agent_go_up" x="20" y="20">
        <next>
            <block type="agent_go_up">
            <next>
                <block type="agent_go_right">
                <next>
                    <block type="agent_go_right">
                    </block>
                </next>
                </block>
            </next>
            </block>
        </next>
        </block>
        </xml>
          `;
    } else if (level === 5) {
      return `
        <xml xmlns="http://www.w3.org/1999/xhtml">
        <block type="initialize_model" x="20" y="20">
        <next>
            <block type="controls_repeat_ext">
                <value name="TIMES">
                    <shadow type="math_number">
                    <field name="NUM">100</field>
                    </shadow>
                </value>
                <statement name="DO">
                    <block type="choose_best_action">
                    <next>
                        <block type="do_best_action">
                        <next>
                            <block type="calculate_reward">
                            <next>
                                <block type="update_model">
                                </block>
                            </next>
                            </block>
                        </next>
                        </block>
                    </next>
                    </block>
                </statement>
            </block>
        </next>
        </block>
        </xml>
              `;
    } else {
      return `
        <xml xmlns="http://www.w3.org/1999/xhtml">
        <block type="initialize_model_extended" x="20" y="20">
        <next>
            <block type="controls_repeat_ext">
                <value name="TIMES">
                    <shadow type="math_number">
                    <field name="NUM">100</field>
                    </shadow>
                </value>
                <statement name="DO">
                    <block type="choose_best_action">
                    <next>
                        <block type="do_best_action">
                        <next>
                            <block type="calculate_reward">
                            <next>
                                <block type="update_model">
                                </block>
                            </next>
                            </block>
                        </next>
                        </block>
                    </next>
                    </block>
                </statement>
            </block>
        </next>
        </block>
        </xml>
              `;
    }
  } else if (subAppName === "ImageClassification") {
    if (level === 5) {
      return `
            <xml>
                <block type="cnn_settings" x="10" y="10">
                </block>
                <block type="dogs" x="200" y="10">
                    <next>
                        <block type="image_dog1">
                            <next>
                                <block type="image_dog2">
                                    <next>
                                        <block type="image_dog3">
                                            <next>
                                                <block type="image_dog4">
                                                    <next>
                                                        <block type="image_dog5">
                                                            <next>
                                                                <block type="image_dog6"></block>
                                                            </next>
                                                        </block>
                                                    </next>
                                                </block>
                                            </next>
                                        </block>
                                    </next>
                                </block>
                            </next>
                        </block>
                    </next>
                </block>
                <block type="cats" x="500" y="10">
                    <next>
                        <block type="image_cat1">
                            <next>
                                <block type="image_cat2">
                                    <next>
                                        <block type="image_cat3">
                                            <next>
                                                <block type="image_cat4">
                                                    <next>
                                                        <block type="image_cat5">
                                                            <next>
                                                                <block type="image_cat6"></block>
                                                            </next>
                                                        </block>
                                                    </next>
                                                </block>
                                            </next>
                                        </block>
                                    </next>
                                </block>
                            </next>
                        </block>
                    </next>
                </block>
            </xml>`;
    } else if (level === 4) {
      return `<xml>
                        <block type="dogs" x="200" y="10">
                            <next>
                                <block type="image_dog1">
                                    <next>
                                        <block type="image_dog4">
                                            <next>
                                                <block type="image_dog5">
                                                </block>
                                            </next>
                                        </block>
                                    </next>
                                </block>
                            </next>
                        </block>
                        <block type="cats" x="500" y="10">
                            <next>
                                <block type="image_cat1">
                                    <next>
                                        <block type="image_cat2">
                                            <next>
                                                <block type="image_cat3">
                                                    <next>
                                                        <block type="image_cat5">
                                                        </block>
                                                    </next>
                                                </block>
                                            </next>
                                        </block>
                                    </next>
                                </block>
                            </next>
                        </block>
                    </xml>
                `;
    } else if (level <= 6) {
      return catsAndDogsSolution;
    } else {
      return `
            <xml>
                <block type="controls_repeat_ext" x="42" y="34">
                    <value name="TIMES">
                        <block type="math_number" id="XTXfH1U9$e?dhaH.e$RV">
                            <field name="NUM">10</field>
                        </block>
                    </value>
                    <statement name="DO">
                        <block type="create_screenshot">
                            <field name="class">class1</field>
                            <next>
                                <block type="wait_seconds">
                                    <field name="seconds">1</field>
                                </block>
                            </next>
                        </block>
                    </statement>
                    <next>
                        <block type="wait_seconds">
                            <field name="seconds">5</field>
                            <next>
                                <block type="controls_repeat_ext">
                                    <value name="TIMES">
                                        <block type="math_number">
                                            <field name="NUM">10</field>
                                        </block>
                                    </value>
                                    <statement name="DO">
                                        <block type="create_screenshot">
                                            <field name="class">class2</field>
                                            <next>
                                                <block type="wait_seconds">
                                                    <field name="seconds">1</field>
                                                </block>
                                            </next>
                                        </block>
                                    </statement>
                                    <next>
                                        <block type="train" >
                                            <next>
                                                <block type="controls_whileUntil">
                                                    <field name="MODE">WHILE</field>
                                                    <value name="BOOL">
                                                        <block type="logic_boolean">
                                                            <field name="BOOL">TRUE</field>
                                                        </block>
                                                    </value>
                                                    <statement name="DO">
                                                        <block type="webcam_predict">
                                                            <next>
                                                                <block type="wait_seconds">
                                                                    <field name="seconds">1</field>
                                                                </block>
                                                            </next>
                                                        </block>
                                                    </statement>
                                                </block>
                                            </next>
                                        </block>
                                    </next>
                                </block>
                            </next>
                        </block>
                    </next>
                </block>
            </xml>
        `;
    }
  }
}

export function getAvailableBlocks(subAppName, level) {
  let mainBlocks = [...blocks[subAppName]];
  if (subAppName === "DecisionTrees") {
    level === 1 && setselectedDataset("vehicle");
    level > 1 &&
      level < 6 &&
      setselectedDataset("weather_data_lvl_" + (level - 1));
    level > 5 &&
      mainBlocks.push(<Block key="data_set_type" type="data_set_type" />);
    return mainBlocks;
  } else if (subAppName === "GridWorld") {
    if (level < 4) {
      return mainBlocks;
    } else if (level === 4) {
      return [mainBlocks, getRepeatBlock(2)];
    } else if (level === 5) {
      return [
        <Block key="initialize_model" type="initialize_model" />,
        getAgentActionBlocks(),
        getRepeatBlock(100),
      ];
    } else {
      return [
        <Block
          key="initialize_model_extended"
          type="initialize_model_extended"
        />,
        getAgentActionBlocks(),
        getRepeatBlock(100),
      ];
    }
  } else {
    if (level <= 6) {
      let availableBlocks =
        level === 6
          ? [
              <Block key={"class_custom"} type={"class_custom"} />,
              <Block key={"image_custom"} type={"image_custom"} />,
            ]
          : mainBlocks;
      for (let i = 1; i < 7; i++) {
        availableBlocks = [
          ...availableBlocks,
          <Block key={"image_dog" + i} type={"image_dog" + i} />,
          <Block key={"image_cat" + i} type={"image_cat" + i} />,
        ];
      }
      return [availableBlocks];
    } else {
      return [
        <Block key="train" type="train" />,
        <Block key="wait_seconds" type="wait_seconds" />,
        <Block key="create_screenshot" type="create_screenshot" />,
        <Block key="webcam_predict" type="webcam_predict" />,
        getRepeatBlock(10),
        <Block key="controls_whileUntil" type="controls_whileUntil" />,
      ];
    }
  }
}
