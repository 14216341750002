// NodeComponent.js
import React from "react";

const NodeComponent = ({ node }) => (
  <li>
    <div className={node.operator ? "decision" : "node"}>
      {node.operator && node.operator[0]}
      {node.data[0]}
    </div>
    {node.subNodes && node.subNodes.length > 0 && (
      <ul>
        {node.subNodes.map((subNode, index) => (
          <NodeComponent key={index} node={subNode} />
        ))}
      </ul>
    )}
  </li>
);

export default NodeComponent;
