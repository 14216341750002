import React, { useState, useEffect } from "react";

const DecisionTreeEvaluator = ({ tree, dataset, onValueChange }) => {
  const [accuracy, setAccuracy] = useState(0);
  const [purity, setPurity] = useState({});
  const [entropy, setEntropy] = useState({});
  const [nodeNames, setNodeNames] = useState([]);

  const evaluateDecisionTree = (tree, dataset) => {
    let correctPredictions = 0;

    for (const instance of dataset) {
      const prediction = classifyInstance(tree, instance);
      const actual = instance.Decision;

      if (prediction === actual) {
        correctPredictions++;
      }
    }

    setAccuracy(correctPredictions / dataset.length);
    traverseTree(tree, dataset);
  };

  // Function to classify instance
  const classifyInstance = (tree, instance) => {
    // Base case: If there are no subNodes, return the classification
    if (!tree.subNodes) {
      return tree.data[0];
    }
    const value = instance[tree.data[0]];

    // Iterate through the subNodes to find the matching condition
    for (let i = 0; i < tree.subNodes.length; i++) {
      const subNode = tree.subNodes[i];
      const operator = subNode.operator[0];
      const nodeValue =
        typeof value === "number" ? Number(subNode.data[0]) : subNode.data[0];

      if (
        (operator === ">=" && value >= nodeValue) ||
        (operator === "<" && value < nodeValue) ||
        (operator === "=" && value === nodeValue) ||
        (operator === "!=" && value !== nodeValue) ||
        (operator === ">" && value > nodeValue) ||
        (operator === "<=" && value <= nodeValue)
      ) {
        return classifyInstance(subNode.subNodes[0], instance);
      }
    }

    // If no conditions match, return a default value
    return null;
  };

  const calculatePurity = (counts) => {
    const total = Object.values(counts).reduce((acc, count) => acc + count, 0);
    const maxCount = Math.max(...Object.values(counts));
    return maxCount / total;
  };

  const calculateEntropy = (counts) => {
    const total = Object.values(counts).reduce((acc, count) => acc + count, 0);
    return Object.values(counts).reduce((acc, count) => {
      if (count === 0) return acc;
      const probability = count / total;
      return acc - probability * Math.log2(probability);
    }, 0);
  };

  const evaluateNode = (instances) => {
    const classCounts = {};
    instances.forEach((instance) => {
      const actual = instance.Decision;
      if (!classCounts[actual]) {
        classCounts[actual] = 0;
      }
      classCounts[actual]++;
    });

    const nodePurity = calculatePurity(classCounts);
    const nodeEntropy = calculateEntropy(classCounts);

    return { purity: nodePurity, entropy: nodeEntropy };
  };

  const traverseTree = (node, instances) => {
    const { purity, entropy } = evaluateNode(instances);
    setPurity((prevPurity) => ({
      ...prevPurity,
      [node.data[0]]: purity,
    }));
    setEntropy((prevEntropy) => ({
      ...prevEntropy,
      [node.data[0]]: entropy,
    }));

    if (!node.subNodes) {
      return;
    }

    node.subNodes.forEach((subNode) => {
      const nextInstances = instances.filter((instance) => {
        const value = instance[node.data[0]];
        const nodeValue =
          typeof value === "number" ? Number(subNode.data[0]) : subNode.data[0];

        switch (subNode.operator[0]) {
          case ">":
            return value > nodeValue;
          case ">=":
            return value >= nodeValue;
          case "<":
            return value < nodeValue;
          case "<=":
            return value <= nodeValue;
          case "=":
            return value === nodeValue;
          case "!=":
            return value !== nodeValue;
          default:
            return false;
        }
      });

      traverseTree(subNode.subNodes[0], nextInstances);
    });
  };

  useEffect(() => {
    onValueChange(accuracy);
  }, [accuracy]);

  useEffect(() => {
    setPurity({});
    setEntropy({});
    setNodeNames([]);
    evaluateDecisionTree(tree, dataset);
  }, [tree, dataset]);

  useEffect(() => {
    setNodeNames(Object.keys(purity));
  }, [purity]);

  const renderPurityEntropyTable = () => {
    return (
      <table>
        <thead>
          <tr>
            <th>Node</th>
            <th>Purity</th>
            <th>Entropy</th>
          </tr>
        </thead>
        <tbody>
          {nodeNames.map((nodeName, index) => (
            <tr key={index}>
              <td>{nodeName}</td>
              <td>{purity[nodeName].toFixed(2)}</td>
              <td>{entropy[nodeName].toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <div>
      {/*<h4>Decision Tree Evaluation Result</h4>*/}
      <h5>Accuracy: {accuracy.toFixed(2)}</h5>
      {/*renderPurityEntropyTable()*/}
    </div>
  );
};

export default DecisionTreeEvaluator;
