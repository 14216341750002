/**
 * @license
 *
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import * as Blockly from "blockly/core";
import i18n from "../../i18n";
import { BLOCK_COLOR } from "../../constants";

Blockly.Blocks["agent_go_down"] = {
  init: function () {
    this.jsonInit({
      type: "agent_go_down",
      message0: i18n.t("grid_world.blocks.move_down"),
      previousStatement: null,
      nextStatement: null,
      colour: BLOCK_COLOR,
      tooltip: "",
      helpUrl: "",
    });
  },
};

Blockly.Blocks["agent_go_up"] = {
  init: function () {
    this.jsonInit({
      type: "agent_go_up",
      message0: i18n.t("grid_world.blocks.move_up"),
      previousStatement: null,
      nextStatement: null,
      colour: BLOCK_COLOR,
      tooltip: "",
      helpUrl: "",
    });
  },
};

Blockly.Blocks["agent_go_left"] = {
  init: function () {
    this.jsonInit({
      type: "agent_go_left",
      message0: i18n.t("grid_world.blocks.move_left"),
      previousStatement: null,
      nextStatement: null,
      colour: BLOCK_COLOR,
      tooltip: "",
      helpUrl: "",
    });
  },
};

Blockly.Blocks["agent_go_right"] = {
  init: function () {
    this.jsonInit({
      type: "agent_go_right",
      message0: i18n.t("grid_world.blocks.move_right"),
      previousStatement: null,
      nextStatement: null,
      colour: BLOCK_COLOR,
      tooltip: "",
      helpUrl: "",
    });
  },
};

Blockly.Blocks["initialize_model"] = {
  init: function () {
    this.jsonInit({
      type: "initialize_model",
      message0: i18n.t("grid_world.blocks.initialize_model"),
      previousStatement: null,
      nextStatement: null,
      colour: BLOCK_COLOR,
      helpUrl: "",
    });
    this.setStyle("list_blocks");
    this.setCommentText(i18n.t("grid_world.blocks.initialize_model_comment"));
    this.comment.setBubbleSize(300, 250);
  },
};

Blockly.Blocks["initialize_model_extended"] = {
  init: function () {
    this.jsonInit({
      type: "initialize_model_extended",
      message0: i18n.t("grid_world.blocks.initialize_model_extended"),
      args0: [
        {
          type: "input_dummy",
        },
        {
          type: "field_number",
          name: "learningRate",
          value: 0.6,
        },
        {
          type: "input_dummy",
        },
        {
          type: "field_number",
          name: "discountFactor",
          value: 0.5,
        },
        {
          type: "input_dummy",
        },
        {
          type: "field_number",
          name: "explorationRate",
          value: 0.1,
        },
      ],
      previousStatement: null,
      nextStatement: null,
      colour: BLOCK_COLOR,
      helpUrl: "",
    });
    this.setStyle("list_blocks");
    this.setCommentText(
      i18n.t("grid_world.blocks.initialize_model_extended_comment")
    );
    this.comment.setBubbleSize(300, 250);
  },
};

Blockly.Blocks["choose_best_action"] = {
  init: function () {
    this.jsonInit({
      type: "choose_best_action",
      message0: i18n.t("grid_world.blocks.choose_best_action"),
      previousStatement: null,
      nextStatement: null,
      colour: BLOCK_COLOR,
      helpUrl: "",
    });
    this.setStyle("list_blocks");
    this.setCommentText(i18n.t("grid_world.blocks.choose_best_action_comment"));
    this.comment.setBubbleSize(300, 250);
  },
};

Blockly.Blocks["do_best_action"] = {
  init: function () {
    this.jsonInit({
      type: "do_best_action",
      message0: i18n.t("grid_world.blocks.do_best_action"),
      previousStatement: null,
      nextStatement: null,
      colour: BLOCK_COLOR,
      helpUrl: "",
    });
    this.setStyle("list_blocks");
    this.setCommentText(i18n.t("grid_world.blocks.do_best_action_comment"));
    this.comment.setBubbleSize(300, 250);
  },
};

Blockly.Blocks["calculate_reward"] = {
  init: function () {
    this.jsonInit({
      type: "calculate_reward",
      message0: i18n.t("grid_world.blocks.calculate_reward"),
      previousStatement: null,
      nextStatement: null,
      colour: BLOCK_COLOR,
      helpUrl: "",
    });
    this.setStyle("list_blocks");
    this.setCommentText(i18n.t("grid_world.blocks.calculate_reward_comment"));
    this.comment.setBubbleSize(300, 250);
  },
};

Blockly.Blocks["update_model"] = {
  init: function () {
    this.jsonInit({
      type: "update_model",
      message0: i18n.t("grid_world.blocks.update_model"),
      previousStatement: null,
      nextStatement: null,
      colour: BLOCK_COLOR,
      helpUrl: "",
    });
    this.setStyle("list_blocks");
    this.setCommentText(i18n.t("grid_world.blocks.update_model_comment"));
    this.comment.setBubbleSize(300, 250);
  },
};
