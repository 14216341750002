import { Button, Modal } from "react-bootstrap";
import React from "react";
import { useTranslation } from "react-i18next";

function LegalInformationModal(props) {
  const { t } = useTranslation();
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t("overview.copy_right_information.legal_information_title")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>{t("overview.copy_right_information.images_title")}</h4>
        <div>
          {t("overview.copy_right_information.images_description")}
          <br />
          <a href="https://de.freepik.com/vektoren-kostenlos/flache-herbstwaldtiersammlung_4969193.htm#query=hase&position=36&from_view=search&track=sph">
            {t("overview.copy_right_information.images_text1")}
          </a>
          <br />
          <a href="https://de.freepik.com/vektoren-kostenlos/flaches-gemuese-und-fruchthintergrund_4112665.htm#query=carrot&position=11&from_view=search&track=sph">
            {t("overview.copy_right_information.images_text2")}
          </a>
        </div>

        <h4>{t("overview.copy_right_information.third_party_library")}</h4>
        <p>{t("overview.copy_right_information.copyright_part1")}</p>
        <p>{t("overview.copy_right_information.copyright_part2")}</p>
        <p>{t("overview.copy_right_information.copyright_part3")}</p>
        <p>{t("overview.copy_right_information.copyright_part4")}</p>
        <p>{t("overview.copy_right_information.copyright_part5")}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export function CopyrightInformationComponent() {
  const [modalShow, setModalShow] = React.useState(false);
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Button onClick={() => setModalShow(true)} variant="secondary">
        {" "}
        {t("overview.copy_right_information.legal_information_title")}
      </Button>
      <LegalInformationModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </React.Fragment>
  );
}
