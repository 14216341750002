/**
 * @license
 *
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { useCallback, useEffect, useRef, useState } from "react";
import "./BlocklyComponent.css";
import "./IntroductionComponent.css";
import Blockly from "blockly/core";
import { javascriptGenerator } from "blockly/javascript";
import "blockly/blocks";

import {
  clearTaskResult,
  getAvailableBlocks,
  getInitialXml,
  getMaxLevel,
  getTaskDescription,
} from "../../constants";
import LevelProgressBarComponent from "./LevelProgressBarComponent";
import { Button, Col, Container, Row } from "react-bootstrap";
import i18n from "../../i18n";
import * as De from "blockly/msg/de";
import * as En from "blockly/msg/en";
import IntroductionComponent from "./IntroductionComponent";
import { useTranslation } from "react-i18next";

function BlocklyComponent(props) {
  const { t } = useTranslation();
  const subApp = props.subApp;
  const subAppName = props.subAppName;
  const [level, setLevel] = useState(1);
  const [currentBlocks, setBlocks] = useState(
    getAvailableBlocks(subAppName, level)
  );
  const [taskResult, setTaskResult] = useState(clearTaskResult());
  const [instructorMode, setInstructorMode] = useState(false);
  const [taskDescription, setTaskDescription] = useState(
    getTaskDescription(subAppName, level)
  );

  const blocklySettings = {
    primaryWorkspace: useRef(),
    blocklyDiv: useRef(),
    toolbox: useRef(),
    blocklyOptionProps: props,
  };

  javascriptGenerator.STATEMENT_PREFIX = "highlightBlock(%1);\n";
  javascriptGenerator.addReservedWords("highlightBlock");

  const updateBlockly = useCallback(
    (level) => {
      setBlocks(() => {
        return getAvailableBlocks(subAppName, level);
      });
    },
    [subAppName]
  );

  const handleChangeLevel = useCallback(
    (level) => {
      setTaskResult(clearTaskResult());
      setLevel(level);
      updateBlockly(level);
    },
    [setLevel, updateBlockly, setTaskResult]
  );

  function handleInstructorMode(activate) {
    setInstructorMode(activate);
    handleChangeLevel(level);
  }

  useEffect(() => {
    blocklySettings.blocklyDiv.current.textContent = "";

    blocklySettings.primaryWorkspace.current = Blockly.inject(
      blocklySettings.blocklyDiv.current,
      {
        toolbox: blocklySettings.toolbox.current,
        zoom: {
          controls: true,
          wheel: false,
          startScale: 1.0,
          maxScale: 3,
          minScale: 0.3,
          scaleSpeed: 1.2,
          pinch: false,
        },
        ...blocklySettings.blocklyOptionProps,
      }
    );
    let initialXml = getInitialXml(subAppName, level, instructorMode);
    Blockly.Xml.domToWorkspace(
      Blockly.utils.xml.textToDom(initialXml),
      blocklySettings.primaryWorkspace.current
    );

    if (i18n.resolvedLanguage === "de") {
      Blockly.setLocale(De);
    } else {
      Blockly.setLocale(En);
    }
  }, [
    level,
    instructorMode,
    blocklySettings.blocklyDiv,
    blocklySettings.primaryWorkspace,
    blocklySettings.toolbox,
    blocklySettings.blocklyOptionProps,
    subAppName,
  ]);

  useEffect(() => {
    setTaskDescription(getTaskDescription(subAppName, level));
  }, [level, subAppName]);

  return (
    <React.Fragment>
      <LevelProgressBarComponent
        currentLevel={level}
        maxLevel={getMaxLevel(subAppName)}
        handleChangeLevel={handleChangeLevel}
      />
      <Container>
        <Row>
          <Col>
            <div className={"turtle-speech-bubble"}>
              <img
                height={728 / 4}
                width={898 / 4}
                src={"/tutorial-turtle-transparent.png"}
                alt={""}
              />
              <span className={"speech-bubble-vertical"}>
                {taskDescription}
              </span>
            </div>
          </Col>
        </Row>
      </Container>

      <div className={"Blockly-Main"}>
        <div className={"Blockly-Editor"}>
          <div ref={blocklySettings.blocklyDiv} id="blocklyDiv" />
          <div style={{ display: "none" }} ref={blocklySettings.toolbox}>
            {currentBlocks}
          </div>
        </div>
        <div className={"Blockly-View"}>
          <subApp.type
            instructorMode={instructorMode}
            handleInstructorMode={handleInstructorMode}
            handleChangeLevel={handleChangeLevel}
            setTaskResult={setTaskResult}
            level={level}
            setLevel={setLevel}
            taskResult={taskResult}
            blocklySettings={blocklySettings}
          />
        </div>
      </div>
      <IntroductionComponent
        showIntroduction={subAppName === "GridWorld" && level === 5}
        headline={t("introduction")}
      />
      <Button
        style={{ position: "absolute", top: "8px", right: "8px" }}
        onClick={() => handleInstructorMode(!instructorMode)}
      >
        {instructorMode
          ? t("overview.settings.button_deactivate_instructor_mode")
          : t("overview.settings.button_activate_instructor_mode")}
      </Button>
    </React.Fragment>
  );
}

export default BlocklyComponent;
