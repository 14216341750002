import React from "react";

function LevelComponent(props) {
  return (
    <div
      onClick={() => props.handleChangeLevel(props.level)}
      className={"level " + (props.isCurrentLevel ? "current-level" : "")}
    >
      {props.level}
    </div>
  );
}

export default LevelComponent;
