import React, { useEffect, useRef, useState } from "react";
import "./ImageClassificationComponent.css";
import { Button, Card, Spinner } from "react-bootstrap";
import { javascriptGenerator } from "blockly/javascript";
import Interpreter from "js-interpreter";
import Blockly from "blockly/core";
import {
  createScreenshotFor,
  createScreenshotForDogsAndCats,
  imagePredict,
  reset,
  trainModel,
  webcamPredict,
} from "../../tenserflow";
import TaskResultComponent from "../Shared/TaskResultComponent";
import WebcamComponent from "./WebcamComponent";
import { PredictImageComponent } from "./PredictImageComponent";
import { useTranslation } from "react-i18next";
import ReactDOM from "react-dom";

function ImageClassificationComponent(props) {
  const { t } = useTranslation();
  const ref = useRef();
  const imageCountPerCategory = 6;
  const setTaskResult = props.setTaskResult;
  const taskResult = props.taskResult;
  const handleChangeLevel = props.handleChangeLevel;

  const { primaryWorkspace } = props.blocklySettings;
  let level = props.level;

  const loadCustomPredictImage = localStorage.getItem("customPredictImage");
  const [image, setImage] = useState(loadCustomPredictImage);

  const [showUploadPredictImage, setShowUploadPredictImage] = useState(false);
  const [showEnterPredictImageURL, setShowEnterPredictImageURL] =
    useState(false);
  const [predictImage, setPredictImage] = useState(null);
  const changePredictImage = (event) => {
    if (
      event.target.value !== undefined &&
      event.target.value !== "Select one Image for prediction"
    ) {
      if (event.target.value === "custom") {
        setShowUploadPredictImage(true);
        setShowEnterPredictImageURL(false);
        if (image !== null) {
          setPredictImage(image);
        }
      } else if (event.target.value === "custom_url") {
        setShowUploadPredictImage(false);
        setShowEnterPredictImageURL(true);
        if (image !== null) {
          setPredictImage(image);
        }
      } else {
        setShowUploadPredictImage(false);
        setShowEnterPredictImageURL(false);
        setPredictImage(
          "/imageClassification/images/" + event.target.value + ".jpg"
        );
      }
    }
  };

  const handleUploadPredictImage = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      setImage(reader.result);
      setPredictImage(reader.result);
      localStorage.setItem("customPredictImage", reader.result);
    };

    reader.readAsDataURL(file);
  };

  const handleEnterPredictImageURL = (e) => {
    e.preventDefault();
    const inputValue = e.target[0].value;
    setImage(inputValue);
    setPredictImage(inputValue);
  };

  function highlightBlock(id) {
    primaryWorkspace.current.highlightBlock(id);
  }

  ///// Start Fraser Interpreter
  function initInterpreterWaitForSeconds(interpreter, globalObject) {
    // Ensure function name does not conflict with variable names.
    javascriptGenerator.addReservedWords("waitForSeconds");

    var wrapper = interpreter.createAsyncFunction(function (
      timeInSeconds,
      callback
    ) {
      // Delay the call to the callback.
      setTimeout(callback, timeInSeconds * 1000);
    });
    interpreter.setProperty(globalObject, "waitForSeconds", wrapper);
  }

  function initApi(interpreter, globalObject) {
    // Add an API function for the alert() block.
    var wrapper = function (text) {
      return alert(arguments.length ? text : "");
    };
    interpreter.setProperty(
      globalObject,
      "alert",
      interpreter.createNativeFunction(wrapper)
    );

    // Add an API function for the prompt() block.
    wrapper = function (text) {
      return prompt(text);
    };
    interpreter.setProperty(
      globalObject,
      "prompt",
      interpreter.createNativeFunction(wrapper)
    );

    wrapper = function (text) {
      return createScreenshotFor(text);
    };
    interpreter.setProperty(
      globalObject,
      "createScreenshotFor",
      interpreter.createNativeFunction(wrapper)
    );

    wrapper = function (text) {
      return trainModel();
    };
    interpreter.setProperty(
      globalObject,
      "trainModel",
      interpreter.createNativeFunction(wrapper)
    );

    wrapper = function (text) {
      return webcamPredict();
    };
    interpreter.setProperty(
      globalObject,
      "webcamPredict",
      interpreter.createNativeFunction(wrapper)
    );

    wrapper = function (id) {
      id = String(id || "");
      return highlightBlock(id);
    };
    interpreter.setProperty(
      globalObject,
      "highlightBlock",
      interpreter.createNativeFunction(wrapper)
    );

    initInterpreterWaitForSeconds(interpreter, globalObject);
  }
  ///// End Move

  // Webcam run code:
  const generateCode = () => {
    window.STATUS = document.getElementById("status");
    ReactDOM.render(
      <span>
        {t("image_classification.program_started")}{" "}
        <Spinner animation="border" />
      </span>,
      ref.current
    );

    setTimeout(() => {
      let code = javascriptGenerator.workspaceToCode(primaryWorkspace.current);

      let myInterpreter = new Interpreter(code, initApi);

      function nextStep() {
        //checkTaskSuccessful();
        //setTaskResult(checkTaskSuccessful(level));
        if (myInterpreter.step()) {
          setTimeout(nextStep, 10);
        } else {
          highlightBlock(null);
        }
      }

      myInterpreter.run();
      nextStep();
    }, 100);
  };

  // check training for level one:
  const checkTrainingData = () => {
    let code = javascriptGenerator.workspaceToCode(primaryWorkspace.current);
    code = code.replace(new RegExp("highlightBlock(.*)\\n", "g"), "");
    let imagesLists = getImageListsFromCode(code);
    let dogsImagesSrc = [];
    let catsImagesSrc = [];

    for (const key in imagesLists) {
      if (Object.hasOwnProperty.call(imagesLists, key)) {
        const imagesSrc = imagesLists[key];
        console.log(key + ": " + imagesSrc);
        if (key === t("image_classification.blocks.dog")) {
          dogsImagesSrc = imagesSrc;
        }
        if (key === t("image_classification.blocks.cat")) {
          catsImagesSrc = imagesSrc;
        }
      }
    }

    let errorMassages = [];

    // Check that all dog images start with "dog"
    for (let i = 0; i < dogsImagesSrc.length; i++) {
      if (!dogsImagesSrc[i].startsWith("dog")) {
        errorMassages.push(t("image_classification.error_start_dog"));
        break;
      }
    }

    // Check that all cat images start with "cat"
    for (let i = 0; i < catsImagesSrc.length; i++) {
      if (!catsImagesSrc[i].startsWith("cat")) {
        errorMassages.push(t("image_classification.error_start_cat"));
        break;
      }
    }

    // Check that there are six images in total
    if (dogsImagesSrc.length < 6) {
      errorMassages.push(
        t("image_classification.error_count_dog", {
          count: dogsImagesSrc.length,
        })
      );
    }
    if (catsImagesSrc.length < 6) {
      errorMassages.push(
        t("image_classification.error_count_cat", {
          count: catsImagesSrc.length,
        })
      );
    }

    if (errorMassages.length > 0) {
      let errorList = (
        <ul>
          {errorMassages.map((value) => {
            return <li>{value}</li>;
          })}
        </ul>
      );

      setTaskResult({
        showTaskResult: true,
        isSuccessful: false,
        headline: t("image_classification.error_default_title"),
        description: t("image_classification.error_default_list"),
        errorList: errorList,
        big: true,
      });
    } else {
      setTaskResult({
        showTaskResult: true,
        isSuccessful: true,
        headline: t("image_classification.success_title"),
        description: t("image_classification.level1.success_description"),
      });
    }
  };

  function getImageListsFromCode(code) {
    let lines = code.split("\n");
    let listImagesSrc = [];
    let currentClassName;
    const classNamePrefix = "CLASS_NAME_";

    for (let i = 0; i < lines.length; i++) {
      if (lines[i] !== null && lines[i].trim() !== "") {
        if (lines[i].startsWith(classNamePrefix)) {
          currentClassName = lines[i].substring(classNamePrefix.length);
        } else {
          if (currentClassName !== undefined) {
            if (!listImagesSrc[currentClassName]) {
              listImagesSrc[currentClassName] = [];
            }
            listImagesSrc[currentClassName].push(lines[i]);
          }
        }
      }
    }
    return listImagesSrc;
  }

  function loadCnnSettings(code) {
    let lines = code.split("\n");
    for (let i = 0; i < lines.length; i++) {
      if (
        lines[i] !== null &&
        lines[i].trim() !== "" &&
        lines[i].startsWith("cnnConfig")
      ) {
        return lines[i].split("#");
      }
    }
  }

  //  image classification run:
  const generateCodeForDogsAndCats = () => {
    window.STATUS = document.getElementById("status");

    reset();

    ReactDOM.render(
      <span>
        {t("image_classification.is_being_trained")}{" "}
        <Spinner animation="border" />
      </span>,
      ref.current
    );

    let code = javascriptGenerator.workspaceToCode(primaryWorkspace.current);
    code = code.replace(new RegExp("highlightBlock(.*)\\n", "g"), "");
    let cnnSettings = loadCnnSettings(code);

    setTimeout(() => {
      let imagesLists = getImageListsFromCode(code);
      for (const key in imagesLists) {
        if (Object.hasOwnProperty.call(imagesLists, key)) {
          const imagesSrc = imagesLists[key];
          imagesSrc.forEach((src) => {
            if (src !== "") {
              const img = document.getElementById(src);
              createScreenshotForDogsAndCats(key, img).then((r) =>
                console.log(r)
              );
            }
          });
        }
      }

      trainModel(cnnSettings);
      let x = 0;
      while (x <= 600) {
        let imagePrediction = setTimeout(function () {
          imagePredict(predictImage);
        }, 1000 * x);
        x++;

        if (!window.imagePredictions) {
          window.imagePredictions = [];
        }
        window.imagePredictions.push(imagePrediction);
      }
      setTaskResult({
        showTaskResult: true,
        isSuccessful: true,
        headline: t("image_classification.success_title"),
        description: t("image_classification.success_description"),
      });
    }, 100);
  };

  function hasNextLevel() {
    return level < 9;
  }

  function handleNextLevel() {
    handleChangeLevel(level + 1);
  }

  const [customImages, setCustomImages] = useState([]);

  const fileSelectorRef = useRef(null);

  const handleChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.addEventListener("load", () => {
      const updatedCustomImages = [...customImages, reader.result];
      setCustomImages(updatedCustomImages);
      const imageIndex = updatedCustomImages.length - 1;

      window.placeholder_image
        .getSourceBlock()
        .getInput("IDENTIFIER")
        .removeField(`IMAGE_ID`);
      window.placeholder_image
        .getSourceBlock()
        .getInput("IDENTIFIER")
        .appendField(
          new Blockly.FieldTextInput(`preview_${imageIndex}`),
          `IMAGE_ID`
        )
        .setVisible(false);
      window.placeholder_image
        .getSourceBlock()
        .getInput("INPUT_IMAGE")
        .removeField(`PLACEHOLDER_IMAGE`);
      window.placeholder_image
        .getSourceBlock()
        .getInput("INPUT_IMAGE")
        .appendField(
          new Blockly.FieldImage(reader.result, 80, 80, "*"),
          `PLACEHOLDER_IMAGE`
        );
    });

    reader.readAsDataURL(file);
  };

  useEffect(() => {
    if (level > 2) {
      reset();
    }

    if (window.imagePredictions) {
      for (let imagePrediction of window.imagePredictions) {
        clearTimeout(imagePrediction);
      }
    }
  }, [props.handleChangeLevel, level]);

  return (
    <React.Fragment>
      <div style={{ display: "none" }}>
        Hidden Upload
        <input
          type="file"
          id="file-selector"
          ref={fileSelectorRef}
          onChange={handleChange}
          accept=".jpg, .jpeg, .png"
        />
        {customImages.map((customImage, index) => (
          <img
            key={index}
            src={customImage}
            id={`preview_${index}`}
            alt={`Preview ${index}`}
          />
        ))}
      </div>

      <div className="status-area">
        {level >= 7 && <WebcamComponent />}

        <div>
          <TaskResultComponent
            showTaskResult={taskResult.showTaskResult}
            setTaskResult={setTaskResult}
            isSuccessful={taskResult.isSuccessful}
            headline={taskResult.headline}
            description={taskResult.description}
            errorList={taskResult.errorList}
            big={taskResult.big}
            hasNextLevel={hasNextLevel()}
            handleNextLevel={handleNextLevel}
          />
          <div className="buttons-bottom">
            {level === 1 && (
              <div>
                <Card>
                  <Card.Header>
                    {t("image_classification.title_finished")}
                  </Card.Header>
                  <Card.Body>
                    <Button variant="success" onClick={checkTrainingData}>
                      {t("image_classification.button_check")}
                    </Button>
                  </Card.Body>
                </Card>
              </div>
            )}

            {level >= 7 && (
              <React.Fragment>
                <Card>
                  <Card.Header>
                    {t("image_classification.title_start")}
                  </Card.Header>
                  <Card.Body>
                    <div>
                      <Button variant="success" onClick={generateCode}>
                        {t("image_classification.button_run_classification")}
                      </Button>
                    </div>
                    <div>
                      <div ref={ref} id="status">
                        {t("image_classification.no_neural_network_trained")}
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </React.Fragment>
            )}
            {level >= 2 && level <= 6 && (
              <div>
                <Card>
                  <Card.Header>
                    {t("image_classification.title_neural_network")}
                  </Card.Header>
                  <Card.Body>
                    <Button
                      variant="success"
                      onClick={generateCodeForDogsAndCats}
                    >
                      {t("image_classification.button_train_neural_network")}
                    </Button>
                    <div>
                      <div ref={ref} id="status">
                        {t("image_classification.no_neural_network_trained")}
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            )}
          </div>
        </div>
      </div>

      {level <= 6 && level >= 3 && (
        <PredictImageComponent
          changePredictImage={changePredictImage}
          showUploadPredictImage={showUploadPredictImage}
          showEnterPredictImageURL={showEnterPredictImageURL}
          handleUploadPredictImage={handleUploadPredictImage}
          handleEnterPredictImageURL={handleEnterPredictImageURL}
          predictImage={predictImage}
        />
      )}

      <div style={{ display: "none" }}>
        {[...Array(imageCountPerCategory * 2)].map((_, index) => {
          const isCat = index < imageCountPerCategory;
          const imageName = isCat
            ? `cat${index + 1}.jpg`
            : `dog${index - imageCountPerCategory + 1}.jpg`;
          const imageId = isCat
            ? `cat${index + 1}`
            : `dog${index - imageCountPerCategory + 1}`;

          return (
            <img
              key={index}
              src={`/imageClassification/images/${imageName}`}
              id={imageId}
              alt=""
            />
          );
        })}
      </div>
    </React.Fragment>
  );
}

export default ImageClassificationComponent;
