import React from "react";
import { Button, Container } from "react-bootstrap";
import BlocklyComponent from "../Shared";
import { getInitialXml } from "../../constants";

function MenuOption({ title, subAppName, subApp, t, navigate }) {
  return (
    <div className="main-div">
      <Container>
        <Button
          onClick={() => {
            navigate("main");
          }}
          variant="outline-primary"
          className="back-button"
        >
          {t("overview.go_back")}
        </Button>
        <h1 style={{ textAlign: "center" }}>{title}</h1>
      </Container>
      <header className="App-header">
        <BlocklyComponent
          subAppName={subAppName}
          subApp={subApp}
          readOnly={false}
          trashcan={true}
          media={"media/"}
          move={{
            scrollbars: false,
            drag: true,
            wheel: true,
          }}
          initialXml={getInitialXml(subAppName, 1)}
        ></BlocklyComponent>
      </header>
    </div>
  );
}

export default MenuOption;
